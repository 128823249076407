import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateUser } from "redux/actions/userActions";
import { Link, withRouter } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { FaChevronDown, FaSignOutAlt, FaExternalLinkAlt } from "react-icons/fa";
import { FiUsers, FiBook } from "react-icons/fi";
import { HiOutlineTicket } from "react-icons/hi";
import {
	AiOutlineCreditCard,
	AiFillDashboard,
	AiOutlineYoutube
} from "react-icons/ai";
import { RiMailSendLine } from "react-icons/ri";
import { MdCardMembership } from "react-icons/md";
import { BsChat, BsBell } from "react-icons/bs";

import "./DashboardLayout.scss";

const DashboardLayout = (props) => {
	const { user, updateUser } = props;
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const userRoutes = [
		{
			icon: <AiFillDashboard />,
			label: "Dashboard",
			routeLink: "/dashboard"
		},
		{
			icon: <FiUsers />,
			label: "Usuarios",
			routeLink: "/usuarios"
		},
		// {
		//     icon: <FiBook />,
		//     label: "Cursos",
		//     routeLink: "/cursos",
		// },
		// {
		//     icon: <BsChat />,
		//     label: "Grupos de Debate",
		//     routeLink: "/grupos-debate",
		// },
		// {
		//     icon: <BsChat />,
		//     label: "Grupos de Interés",
		//     routeLink: "/grupos-interes",
		// },
		{
			icon: <BsBell />,
			label: "Notificaciones",
			routeLink: "/notificaciones"
		},
		{
			icon: <BsChat />,
			label: "Chats",
			routeLink: "/chats?type=administrator"
		},
		// {
		//     icon: <HiOutlineTicket />,
		//     label: "Eventos",
		//     routeLink: "/eventos",
		//     externalLink: "https://sistema.eventiza.com.br/",
		// },
		{
			icon: <RiMailSendLine />,
			label: "Sugerencias",
			routeLink: "/sugerencias"
		},
		// {
		//     icon: <AiOutlineCreditCard />,
		//     label: "Pagos",
		//     routeLink: "/pagos",
		// },
		// {
		//     icon: <MdCardMembership />,
		//     label: "Certificados de Membresías",
		//     routeLink: "/membership-certificates"
		// },
		{
			icon: <BsChat />,
			label: "Soporte",
			routeLink: "/chats?type=support"
		}
		// {
		//     icon: <AiOutlineYoutube />,
		//     label: "Youtube",
		//     routeLink: "/youtube-videos",
		// }
	];

	// const adminRoutes = [
	//     {
	//         icon: <FontAwesomeIcon icon={faUserLock} />,
	//         label: 'Admin Page',
	//         routeLink: '/admin/custom-page',
	//     },
	// ];

	// const routes = user.role === 'Admin' ? [...userRoutes, ...adminRoutes] : [...userRoutes];

	const routes = userRoutes;

	const {
		children,
		location: { pathname }
	} = props;
	const currentPath = pathname;

	const username = user.name;
	const userphoto = user.urlProfileImage;

	const logout = () => {
		updateUser({});
		localStorage.clear();
	};

	return (
		<div className="dashboard-layout">
			<div className="sidebar">
				<div className="logo">
					<img
						src={require("assets/images/logo-horizontal.png")}
						alt="logo-full"
					/>
				</div>

				{routes.map((route, i) => {
					let active = route.routeLink.includes(
						currentPath + props.location.search
					)
						? "active"
						: "";
					return (
						<div key={i} className={`sidebar-item ${active}`}>
							<Link
								to={route.routeLink}
								key={i}
								className="sidebar-item-wrapper"
							>
								<div className="sidebar-icon">{route.icon}</div>
								<div className="sidebar-label">{route.label}</div>
							</Link>
							{route.externalLink ? (
								<a
									className="sidebar-external-link"
									href={route.externalLink}
									target="_blank"
								>
									<FaExternalLinkAlt />
								</a>
							) : null}
						</div>
					);
				})}
			</div>

			<div className="content">
				<div className="top-bar">
					<div className="logged-user-info">
						<OutsideClickHandler
							onOutsideClick={(e) => setIsDropdownOpen(false)}
						>
							<div
								className="logged-user-info__content"
								onClick={() => setIsDropdownOpen(!isDropdownOpen)}
							>
								<img
									src={
										userphoto || require("assets/images/user-placeholder.jpg")
									}
									alt="user-placeholder"
								/>
								<span title={username}>{username}</span>
								<FaChevronDown />
							</div>

							{isDropdownOpen && (
								<div className="logged-user-info__options">
									<div className="option" onClick={() => logout()}>
										<FaSignOutAlt />
										<span>Cerrar sesión</span>
									</div>
								</div>
							)}
						</OutsideClickHandler>
					</div>
				</div>
				<div className="content-children">{children}</div>
			</div>
		</div>
	);
};

const mapStateToProps = (store) => ({
	user: store.userReducer.user
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ updateUser }, dispatch);

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(DashboardLayout)
);
