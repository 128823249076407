import React from "react";
import { FaEllipsisV } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import "./SkeletonCourseCard.scss";

const SkeletonCourseCard = (props) => {
	return (
		<div className="skeleton-course-card">
			<div className="main-content">
				<div className="title">
					<div className="course-title">
						<Skeleton width={130} height={12} />
					</div>
				</div>

				<div className="description">
					<Skeleton width={350} height={12} />
					<Skeleton width={300} height={12} />
				</div>
			</div>

			<div className="action">
				<div className="dots-icon">
					<FaEllipsisV />
				</div>
			</div>
		</div>
	);
};

export default SkeletonCourseCard;
