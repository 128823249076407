import React, { useEffect, useState, useRef } from "react";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import "./ChatComponent.scss";
import moment from "moment";
import SkeletonChatCard from "components/elements/SkeletonChatCard/SkeletonChatCard";
import _ from "lodash";
import "moment/locale/es";
moment.locale("es");

const ChatComponent = (props) => {
	const {
		loadingChats,
		chats,
		goToChat,
		messages,
		sendMessage,
		chatName,
		chatUserPhoto,
		profileIdAdmin
	} = props;
	const [message, setMessage] = useState(undefined);

	//const testChats = [...chats, {name:"test"}, {name:"test"}, {name:"test"}, {name:"test"}, {name:"test"}, {name:"test"}, {name:"test"}];

	const chatRef = useRef();

	const scrollToBottom = () => {
		chatRef.current.scrollIntoView({
			behavior: "smooth",
			block: "start"
		});
	};

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			if (message !== undefined && message.length !== 0) {
				setMessage("");
				sendMessage(message);
			}
		}
	};

	useEffect(() => {
		if (messages !== undefined && messages.length !== 0) scrollToBottom();
	}, [messages]);

	const formatDate = (time) => {
		const now = moment();
		const messageTime = moment(time);

		if (now.date() === messageTime.date()) {
			return messageTime.format("HH:mm");
		} else if (now.date() - 1 === messageTime.date()) {
			return messageTime.calendar(now);
		} else {
			return messageTime.format("D/M/YY");
		}
		/*if (now.date() === messageTime.date()) {
            return messageTime.format("HH:mm");
        } else if (now.date() - 1 === messageTime.date()) {
            return messageTime.calendar(now);
        } else if (now.year() === messageTime.year()) {
            return messageTime.format("DD/MMM");
        } else {
            return messageTime.format("ll");
        }*/
	};

	return (
		<DashboardLayout>
			<div className="chats-wrapper">
				<div style={{ overflowY: "auto" }} className="chats-contacts">
					<div className="section-title">
						<h1>Chats</h1>
					</div>
					{chats !== undefined && chats.length !== 0
						? chats.map((item, k) => {
								const lastMessage = item.lastMessage;
								return (
									<div
										key={k}
										className={`chat-card ${item.badge !== 0 && `notification`}`}
										onClick={() => goToChat(item)}
									>
										<div className="photo-text-container">
											<img className="photo" src={item.photo} alt="" />
											<div className="text">
												<div className="name">
													{item.name} {item.surname}
												</div>
												<div className="message-container">
													<div className="message-content">
														{lastMessage?.message?.content}
													</div>
													<div className="message-date">
														{formatDate(lastMessage?.timestamp)}
													</div>
												</div>
											</div>
										</div>
										<div className="notification-container">
											{item.badge !== 0 && (
												<div className="notification">{item.badge}</div>
											)}
										</div>
									</div>
								);
							})
						: loadingChats && _.times(3, (i) => <SkeletonChatCard key={i} />)}
				</div>
				<div className="chats">
					{chatName && (
						<div className="name-container">
							<img className="photo" src={chatUserPhoto} alt="" />
							<div className="name">{chatName}</div>
						</div>
					)}
					<div className="message-container">
						{messages !== undefined &&
							messages.map((item, index) => {
								const isAdmin = item.userId === profileIdAdmin;
								const isLastMessage =
									messages.length - 1 == index ? chatRef : null;
								return (
									<div style={{ marginBottom: 10 }} ref={isLastMessage}>
										{index === 0 && (
											<div
												style={{
													textAlign: "center",
													paddingTop: 20,
													color: "#C4C4C4"
												}}
											>
												{moment(item.timestamp).format("L")}
											</div>
										)}
										{isAdmin ? (
											<div className="message isAdmin">
												<div className="text-container">
													<div className="message-text isAdmin">
														{item.message.content}
													</div>
													<div className="message-timestamp isAdmin">
														{moment(item.timestamp).format("LT")}
													</div>
												</div>
												<img
													className="photo isAdmin"
													src={require("assets/images/icon.png")}
													alt=""
												/>
											</div>
										) : (
											<div className="message">
												<img className="photo" src={item.photo} alt="" />
												<div className="text-container">
													<div className="message-text">
														{item.message.content}
													</div>
													<div className="message-timestamp">
														{moment(item.timestamp).format("LT")}
													</div>
												</div>
											</div>
										)}
									</div>
								);
							})}
					</div>
					{chatName && (
						<div className="message-input">
							<input
								className="input"
								placeholder="Escribe tu mensaje aquí..."
								value={message}
								onKeyPress={handleKeyPress}
								onChange={(e) => setMessage(e.target.value)}
							></input>
							<img
								onClick={() => {
									if (message !== undefined && message.length !== 0) {
										setMessage("");
										sendMessage(message);
									}
								}}
								className={`icon ${(message === undefined || message.length === 0) && `disabled`}`}
								src={require("assets/images/sendMessageIcon.png")}
								alt=""
							/>
						</div>
					)}
				</div>
			</div>
		</DashboardLayout>
	);
};

export default ChatComponent;
