import React from "react";
import "./StripeComponent.scss";

const StripeComponent = (props) => {
	const { messageError } = props;

	return (
		<div>
			<div className="loading">
				<img
					src="https://sicomoro-netchain.ams3.digitaloceanspaces.com/images/layout/animation_500_kf5c0xzn.gif"
					alt="loading"
				/>
			</div>
			<span> {messageError} </span>
		</div>
	);
};

export default StripeComponent;
