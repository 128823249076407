import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import OutsideClickHandler from "react-outside-click-handler";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { AiOutlineEdit } from "react-icons/ai";
import {
	FaPlus,
	FaEllipsisV,
	FaRegTrashAlt,
	FaSort,
	FaSortUp,
	FaSortDown
} from "react-icons/fa";
import { MdCardMembership } from "react-icons/md";
import Modal from "react-modal";
import Button from "components/elements/Button/Button";
import PaginationNavigator from "components/elements/PaginationNavigator/PaginationNavigator";
import MaterialInput from "components/elements/MaterialInput/MaterialInput";
import SearchBar from "components/elements/SearchBar/SearchBar";
import { Formik, Form } from "formik";
import * as yup from "yup";
import moment from "moment";
import "./UsersComponent.scss";
import { createCarnetService } from "services/user";

const UsersComponent = (props) => {
	const {
		users,
		deleteUser,
		deleteUserLoading,
		submitUser,
		addUser,
		isDeleteModalOpen,
		setIsDeleteModalOpen,
		isManageUserModalOpen,
		setIsManageModalUserOpen,
		isCreateUserModalOpen,
		setIsCreateUserModalOpen,
		isNewUserClubMember,
		setIsNewUserClubMember,
		isRegisteringUser,
		setCarnetUser,
		selectedUser,
		setSelectedUser,
		submitUserLoading,
		paginationTotal,
		paginationCurrent,
		paginationStep,
		paginationUrl,
		searchbarInput,
		setSearchbarInput,
		searchbarResults,
		setSearchbarResults,
		searchbarShowing,
		setSearchbarShowing,
		totalUsers,
		sortConfiguration,
		setSortConfiguration,
		tableHeaderRef,
		errorModal,
		isErrorModal
	} = props;

	const [optionsOpen, setOptionsOpen] = useState(null);
	const searchableParameters = ["name", "surname", "email", "nif", "phone"];
	const [headersArrows, setHeadersArrows] = useState({
		name: <FaSort />,
		surname: <FaSort />,
		email: <FaSort />,
		nif: <FaSort />
	});
	const history = useHistory();

	const deleteClickCallback = (user) => {
		console.log("open delete");
		setSelectedUser(user);
		setIsDeleteModalOpen(true);
	};

	const editClickCallback = (user) => {
		console.log("open edit", user);
		setSelectedUser(user);
		setIsManageModalUserOpen(true);
	};

	const carnetCreateCallback = (user) => {
		createCarnetService(
			{
				type: "club",
				extraData: {
					nif: user.nif
				}
			},
			user.profileId
		);
	};

	const certificateClickCallback = (user) => {
		history.push(`/donations-certificate/${user.profileId}`);
	};

	const updateSearchbarInput = async (input) => {
		if (input.length > 0) {
			if (!searchbarShowing) setSearchbarShowing(true);

			const filtered = totalUsers.filter((user) => {
				if (input == "") return true;

				for (var [key, value] of Object.entries(user)) {
					if (
						searchableParameters.includes(key) &&
						value.toLowerCase().includes(input.toLowerCase())
					)
						return true;
				}

				return false;
			});
			setSearchbarInput(input);
			setSearchbarResults(filtered);
		} else {
			setSearchbarInput(input);
			setSearchbarResults(users);
			setSearchbarShowing(false);
		}
	};

	const requestSort = async (field) => {
		let temporalHeadersArrows = { ...headersArrows };

		Object.keys(temporalHeadersArrows).map((key) => {
			temporalHeadersArrows[key] = <FaSort />;
		});

		let direction = "ascending";
		temporalHeadersArrows[field] = <FaSortUp />;

		if (sortConfiguration && sortConfiguration.direction == "ascending") {
			direction = "descending";
			temporalHeadersArrows[field] = <FaSortDown />;
		}

		console.log("HEADERS ARROWS", field);

		setHeadersArrows(temporalHeadersArrows);
		setSortConfiguration({ key: field, direction: direction });
	};

	return (
		<DashboardLayout>
			<div className="users-wrapper">
				<div className="section-title">
					<h1>Usuarios</h1>
					<div className="users-button-holder">
						<Button
							className="secondary small"
							onClick={() => {
								setIsCreateUserModalOpen(true);
							}}
						>
							<FaPlus />
							<span>Añadir usuario</span>
						</Button>
						<SearchBar input={searchbarInput} onChange={updateSearchbarInput} />
					</div>
				</div>

				<div className="pagination-top">
					<PaginationNavigator
						total={paginationTotal}
						current={paginationCurrent}
						step={paginationStep}
						url={paginationUrl}
						showing={!searchbarShowing}
					/>
				</div>

				<table>
					<thead ref={tableHeaderRef}>
						<tr>
							<th>Fecha de registro</th>
							<th
								className="title-sortable"
								onClick={(e) => {
									requestSort("name");
								}}
							>
								Nombre {headersArrows.name}
							</th>
							<th
								className="title-sortable"
								onClick={() => {
									requestSort("surname");
								}}
							>
								Apellidos {headersArrows.surname}
							</th>
							<th
								className="title-sortable"
								onClick={() => {
									requestSort("email");
								}}
							>
								Email {headersArrows.email}
							</th>
							<th
								className="title-sortable"
								onClick={() => {
									requestSort("nif");
								}}
							>
								NIF {headersArrows.nif}
							</th>
							<th>Teléfono</th>
						</tr>
					</thead>
					<tbody>
						{searchbarResults.length ? (
							searchbarResults.map((user, i) => (
								<tr key={i}>
									<td>{moment(user.createdAt).format("DD/MM/YYYY")}</td>
									<td>{user.name}</td>
									<td>{user.surname}</td>
									<td>{user.email}</td>
									<td>{user.nif}</td>
									<td>{user.phone}</td>
									<td>
										<div className="action">
											<div
												className="dots-icon"
												onClick={() =>
													optionsOpen ? setOptionsOpen(null) : setOptionsOpen(i)
												}
											>
												<FaEllipsisV />
											</div>

											{optionsOpen === i && (
												<div className="options">
													<OutsideClickHandler
														onOutsideClick={(e) => {
															e.stopPropagation();
															e.preventDefault();
															setOptionsOpen(null);
														}}
													>
														<div
															className="option"
															onClick={() => certificateClickCallback(user)}
														>
															<MdCardMembership size={"20px"} />
															<span>Certificados</span>
														</div>

														<div
															className="option"
															onClick={() => editClickCallback(user)}
														>
															<AiOutlineEdit size={"20px"} />
															<span>Editar</span>
														</div>

														<div
															className="option red"
															onClick={() => deleteClickCallback(user)}
														>
															<FaRegTrashAlt size={"20px"} />
															<span>Eliminar</span>
														</div>
													</OutsideClickHandler>
												</div>
											)}
										</div>
									</td>
								</tr>
							))
						) : (
							<div>No hay usuarios registrados para mostrar.</div>
						)}
					</tbody>
				</table>

				<div className="pagination-bottom">
					<PaginationNavigator
						total={paginationTotal}
						current={paginationCurrent}
						step={paginationStep}
						url={paginationUrl}
						showing={!searchbarShowing}
					/>
				</div>
			</div>
			<Modal
				isOpen={isDeleteModalOpen}
				onRequestClose={() => setIsDeleteModalOpen(false)}
				className="sicomoro-modal"
				overlayClassName="modal-overlay with-background"
				contentLabel="Example Modal"
			>
				<div className="delete-modal__wrapper">
					<div className="modal-body">
						<FaRegTrashAlt />
						<h3>¿Eliminar {selectedUser?.name}?</h3>
					</div>
					<div className="modal-footer">
						<Button
							className="light"
							onClick={() => setIsDeleteModalOpen(false)}
						>
							Cancelar
						</Button>
						<Button
							className="danger"
							loading={deleteUserLoading}
							onClick={() => deleteUser(selectedUser?.profileId)}
						>
							Eliminar
						</Button>
					</div>
				</div>
			</Modal>

			<Modal
				isOpen={isManageUserModalOpen}
				onRequestClose={() => setIsManageModalUserOpen(false)}
				className="sicomoro-modal"
				overlayClassName="modal-overlay with-background"
				ariaHideApp={false}
			>
				<div className="manage-user-modal__wrapper">
					<div className="modal-header">
						<div>
							{selectedUser?.profileId ? "Editar usuario" : "Añadir usuario"}
						</div>
					</div>

					<Formik
						enableReinitialize
						initialValues={{
							createdAtFormatted:
								moment(selectedUser.createdAt).format("DD/MM/YYYY HH:mm") || "",
							name: selectedUser.name || "",
							surname: selectedUser.surname || "",
							nif: selectedUser.nif || "",
							phone: selectedUser.phone || "",
							email: selectedUser.email || "",
							address: selectedUser.address || "",
							orcid: selectedUser.orcid || "",
							/*picture: selectedUser.image || '',
                            checkconfirmed: selectedUser.confirmed || '',
                            checknewsletter: selectedUser.newsletter || '',
                            checkchatallowed: selectedUser.chatAllowed || '',*/
						}}
						validationSchema={yup.object().shape({
							name: yup.string(),
							surname: yup.string(),
							nif: yup.string()
							/*phone: yup.string(),
                                email: yup.string(),*/
						})}
						onSubmit={(values) => submitUser(values, selectedUser?.profileId)}
					>
						{(formik) => (
							<Form>
								<div className="modal-body">
									<div className="input-wrapper fill-2">
										<div className="modal-circular-image">
											<img
												className="modal-avatar"
												src={selectedUser.image.url}
											/>
										</div>
									</div>

									<div className="input-wrapper fill-4">
										<MaterialInput
											type="text"
											id="name"
											label="Nombre"
											placeholder="Nombre"
											className="modal-separator"
										/>

										<MaterialInput
											formik={formik}
											type="text"
											id="surname"
											label="Apellidos"
											className="modal-separator"
											placeholder="Apellidos"
										/>

										<MaterialInput
											formik={formik}
											type="text"
											id="nif"
											label="NIF"
											placeholder="NIF"
										/>
									</div>

									<div className="input-wrapper fill-row">
										<MaterialInput
											type="text"
											id="address"
											label="Dirección"
											placeholder="Dirección"
										/>
									</div>

									<div className="input-wrapper input-setion-title fill-row">
										<h4>
											Información del usuario <i>(Solo lectura)</i>
										</h4>
									</div>

									<div className="input-wrapper fill-2">
										<MaterialInput
											formik={formik}
											type="text"
											id="createdAtFormatted"
											label="Fecha de registro"
											placeholder="Fecha de registro"
											inputProps={{
												readOnly: true
											}}
										/>
									</div>

									<div className="input-wrapper fill-2">
										<MaterialInput
											type="text"
											id="email"
											label="Email"
											placeholder="Email"
											inputProps={{
												readOnly: true
											}}
										/>
									</div>

									<div className="input-wrapper fill-2">
										<MaterialInput
											type="text"
											id="phone"
											label="Télefono"
											placeholder="Télefono"
											inputProps={{
												readOnly: true
											}}
										/>
									</div>

									<div className="input-wrapper fill-2">
										<MaterialInput
											type="text"
											id="postalCode"
											label="Código Postal"
											placeholder="Código Postal"
											inputProps={{
												readOnly: true
											}}
										/>
									</div>

									<div className="input-wrapper fill-2">
										<FormControlLabel
											inputProps={{
												readOnly: true
											}}
											control={<Checkbox checked={selectedUser.confirmed} />}
											label="Activado"
										/>
									</div>

									<div className="input-wrapper fill-2">
										<FormControlLabel
											inputProps={{
												readOnly: true
											}}
											control={<Checkbox checked={selectedUser.newsletter} />}
											label="Newsletter"
										/>
									</div>

									<div className="input-wrapper fill-2">
										<FormControlLabel
											inputProps={{
												readOnly: true
											}}
											control={<Checkbox checked={selectedUser.chatAllowed} />}
											label="Acceso al chat"
										/>
									</div>

									{selectedUser.nif && (
										<>
											<div className="carnets-info fill-row">
												<h4>Carnets</h4>
											</div>

											{selectedUser.carnets ? (
												<div className="carnets-table fill-row">
													<table>
														<thead>
															<tr>
																<th>Nº</th>
																<th>Tipo</th>
																<th>Fecha de alta</th>
															</tr>
														</thead>
														<tbody>
															{selectedUser.carnets.map((carnet, i) => (
																<tr>
																	<td>
																		{"#"}
																		{i + 1}
																	</td>
																	<td>
																		{carnet.type.charAt(0).toUpperCase() +
																			carnet.type.slice(1)}
																	</td>
																	<td>
																		{moment(carnet.createdAt).format(
																			"DD/MM/YYYY HH:mm"
																		)}
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												</div>
											) : (
												<p>Este usuario no dispone de carnets aún</p>
											)}

											{selectedUser.carnets?.findIndex(
												(carnet) => carnet.type === "club"
											) == -1 && (
												<div className="carnets-button">
													<Button
														onClick={() => carnetCreateCallback(selectedUser)}
													>
														Añadir carnet del Club
													</Button>
												</div>
											)}
										</>
									)}

									{selectedUser.paymentInfo && (
										<div className="payments-info fill-row">
											<div className="input-wrapper input-setion-title fill-row">
												<h4>Métodos de pago</h4>
												<div className="input-wrapper payments-table fill-row">
													<table>
														<thead>
															<tr>
																<th>Método</th>
																<th>Tipo</th>
																<th>Información</th>
															</tr>
														</thead>
														<tbody>
															{selectedUser.paymentInfo.map(
																(payment, i) =>
																	({
																		"bank-account": (
																			<tr>
																				<td>
																					{"#"}
																					{i + 1}
																				</td>
																				<td>{"Domiciliación bancaria"}</td>
																				<td>
																					<p>
																						<strong>Titular: </strong>
																						{
																							payment.bankAccountData
																								?.accountName
																						}
																					</p>
																					<p>
																						<strong>IBAN: </strong>
																						{
																							payment.bankAccountData
																								?.accountNumber
																						}
																					</p>
																				</td>
																			</tr>
																		),
																		stripe: (
																			<tr>
																				<td>
																					{"#"}
																					{i + 1}
																				</td>
																				<td>{"Stripe"}</td>
																				<td>
																					<p>
																						<strong>ID: </strong>
																						{payment.stripeData?.customerId}
																					</p>
																				</td>
																			</tr>
																		)
																	})[payment.paymentType]
															)}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									)}
								</div>

								<div className="modal-footer">
									<Button
										className="light"
										onClick={() => setIsManageModalUserOpen(false)}
									>
										Volver
									</Button>
									<Button
										type="submit"
										onClick={() => formik.handleSubmit()}
										loading={submitUserLoading}
									>
										{selectedUser?.profileId ? "Confirmar" : "Añadir"}
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>

			<Modal
				isOpen={isCreateUserModalOpen}
				onRequestClose={() => setIsCreateUserModalOpen(false)}
				className="sicomoro-modal"
				overlayClassName="modal-overlay with-background"
				ariaHideApp={false}
			>
				<div className="manage-user-modal__wrapper">
					<div className="modal-header">
						<div>Añadir usuario</div>
					</div>

					<Formik
						enableReinitialize
						initialValues={{
							createdAtFormatted:
								moment(selectedUser.createdAt).format("DD/MM/YYYY HH:mm") || ""
						}}
						validationSchema={yup.object().shape({
							name: yup.string().required("Nombre obligatorio"),
							surname: yup.string().required("Apellidos obligatorios"),
							phoneprefix: yup.string().required("Obligatorio"),
							phone: yup.string().required("Teléfono obligatorio"),
							email: yup.string().required("Email obligatorio"),
							password: yup
								.string()
								.min(6)
								.required("Contraseña de 6 caracteres obligatoria"),
							repeatpassword: yup
								.string()
								.oneOf(
									[yup.ref("password"), null],
									"Las contraseñas deben coincidir"
								)
								.required("Confirmación de contraseña obligatorio")
						})}
						onSubmit={(values) => {
							addUser(values);
						}}
					>
						{(formik) => (
							<Form>
								<div className="modal-body">
									<div className="input-wrapper fill-3">
										<MaterialInput
											formik={formik}
											type="text"
											id="name"
											label="Nombre"
											placeholder="Nombre"
										/>
									</div>

									<div className="input-wrapper fill-3">
										<MaterialInput
											formik={formik}
											type="text"
											id="surname"
											label="Apellidos"
											placeholder="Apellidos"
										/>
									</div>

									<div className="input-wrapper fill-1">
										<MaterialInput
											formik={formik}
											type="text"
											id="phoneprefix"
											label="Prefijo"
											placeholder="+34"
										/>
									</div>

									<div className="input-wrapper fill-2">
										<MaterialInput
											formik={formik}
											type="text"
											id="phone"
											label="Télefono"
											placeholder="Télefono"
										/>
									</div>

									<div className="input-wrapper fill-3">
										<MaterialInput
											formik={formik}
											type="text"
											id="email"
											label="Email"
											placeholder="Email"
										/>
									</div>

									<div className="input-wrapper fill-3">
										<MaterialInput
											formik={formik}
											type="password"
											id="password"
											label="Contraseña"
										/>
									</div>

									<div className="input-wrapper fill-3">
										<MaterialInput
											formik={formik}
											type="password"
											id="repeatpassword"
											label="Repetir contraseña"
											placeholder=""
										/>
									</div>
									<div className="input-wrapper input-setion-title fill-row">
										<Button
											className="light"
											type="button"
											onClick={() => {
												let genPass = "";
												let mask = "";
												const chars = "aA#!";
												const length = 10;
												if (chars.indexOf("a") > -1) {
													mask += "abcdefghijklmnopqrstuvwxyz";
												}
												if (chars.indexOf("A") > -1) {
													mask += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
												}
												if (chars.indexOf("#") > -1) {
													mask += "0123456789";
												}
												if (chars.indexOf("!") > -1) {
													mask += "!@#$%&*()_+-={}[]:<>?,.";
												}
												for (let i = length; i > 0; --i) {
													genPass +=
														mask[Math.round(Math.random() * (mask.length - 1))];
												}
												formik.setFieldValue("password", genPass);
												formik.setFieldValue("repeatpassword", genPass);
											}}
										>
											Generar contraseña
										</Button>
									</div>

									<div className="input-wrapper input-setion-title fill-row">
										<MaterialInput
											formik={formik}
											type="text"
											id="address"
											label="Dirección"
											placeholder=""
										/>
									</div>
									<div className="input-wrapper input-setion-title fill-row">
										<h4>
											Membresía <i>(Opcional)</i>
										</h4>
									</div>

									<div className="input-wrapper fill-2">
										<FormControlLabel
											control={
												<Checkbox
													onChange={(e) => {
														setIsNewUserClubMember(e.target.checked);
													}}
												/>
											}
											label="¿Carnet de Autor?"
										/>
									</div>

									{isNewUserClubMember && (
										<>
											<div className="input-wrapper fill-2">
												<MaterialInput
													formik={formik}
													type="text"
													id="orcid"
													label="ORCID"
													placeholder="ORCID"
												/>
											</div>
										</>
									)}
									<div className="fill user-separator"></div>
								</div>

								{isErrorModal && (
									<>
										<div className="modal-error">
											<div className="modal-message">{errorModal}</div>
										</div>
									</>
								)}

								<div className="modal-footer">
									<Button
										className="light"
										type="button"
										onClick={() => setIsCreateUserModalOpen(false)}
									>
										Volver
									</Button>
									<Button
										type="submit"
										onClick={() => formik.handleSubmit()}
										loading={isRegisteringUser}
									>
										Añadir
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</DashboardLayout>
	);
};

export default UsersComponent;
