import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser } from "redux/actions/userActions";
import DonationCertificateComponent from "../components/DonationCertificateComponent";
import { useParams } from "react-router";
import {
	getUploadDonationCertificateService,
	uploadDonationCertificateService,
	uploadImageService,
	getCertificatesService,
	getCertificateByIdService
} from "services/certificates";
import { getUsersService } from "services/user";
import { getFileBuffer } from "utils/imageFormatter";

//getUploadDonationCertificateService

const DonationCertificate = (props) => {
	const [isManageCertificateModalOpen, setIsManageCertificateModalOpen] =
		useState(false);
	const [submitCertificateLoading, setSubmitCertificateLoading] =
		useState(false);
	const [certificates, setCertificates] = useState([]);
	const [certificatesFetching, setCertificatesFetching] = useState(false);
	const [user, setUser] = useState({});
	let { id } = useParams();

	useEffect(() => {
		getUser();
	}, []);

	useEffect(() => {
		getCertificates();
	}, []);

	const getUser = async () => {
		const users = await getUsersService();
		const userSelected = users.find((user) => user.profileId == id);
		setUser(userSelected);
	};

	const getCertificates = async () => {
		setCertificatesFetching(true);
		try {
			const data = await getCertificatesService(id);
			setCertificates(data);
		} catch (err) {
			console.log(err);
		}

		setCertificatesFetching(false);
	};

	const getCertificateById = async (id) => {
		try {
			const certificate = await getCertificateByIdService(id);
			return certificate.url;
		} catch (err) {
			console.log(err);
		}
	};

	const submitCertificate = async (formValues) => {
		setSubmitCertificateLoading(true);
		try {
			await uploadFile(formValues.picture.file, parseInt(formValues.year));
			setIsManageCertificateModalOpen(false);
		} catch (err) {
			console.log(err);
		} finally {
			setSubmitCertificateLoading(false);
		}
	};
	const getUrlFile = async (file) => {
		return file.name;
	};

	const uploadFile = async (file, year) => {
		const urlToUpload = await getUploadDonationCertificateService({
			profileId: id,
			year,
			key: file.name,
			mimeType: file.type
		});

		const fileBuffer = await getFileBuffer(file);

		await uploadImageService({
			url: urlToUpload.url,
			body: fileBuffer,
			mimeType: urlToUpload.mimeType
		});

		const response = await uploadDonationCertificateService({
			profileId: id,
			year,
			file: urlToUpload.fileData
		});

		let finalUrl = response?.file?.url?.split("?")[0];

		return finalUrl;
	};

	return (
		<DonationCertificateComponent
			{...props}
			isManageCertificateModalOpen={isManageCertificateModalOpen}
			setIsManageCertificateModalOpen={setIsManageCertificateModalOpen}
			submitCertificateLoading={submitCertificateLoading}
			submitCertificate={submitCertificate}
			getUrlFile={getUrlFile}
			user={user}
			certificates={certificates}
			getCertificateById={getCertificateById}
			certificatesFetching={certificatesFetching}
		/>
	);
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ updateUser }, dispatch);

export default withRouter(
	connect(null, mapDispatchToProps)(DonationCertificate)
);
