import React from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import AuthLayout from "layouts/AuthLayout/AuthLayout";
import Button from "components/elements/Button/Button";
import MaterialInput from "components/elements/MaterialInput/MaterialInput";
import "./LoginComponent.scss";

const LoginComponent = (props) => {
	const { loading, submit, error } = props;

	return (
		<AuthLayout>
			<div className="login-component">
				<Formik
					initialValues={{ email: "", password: "" }}
					validationSchema={yup.object().shape({
						email: yup
							.string()
							.email("El formato es inválido")
							.required("Campo obligatorio"),
						password: yup.string().required("Campo obligatorio")
					})}
					onSubmit={(values) => submit(values)}
				>
					{(formik) => (
						<>
							<Form>
								<MaterialInput
									id="email"
									formik={formik}
									type="text"
									label="Email*"
									placeholder="Escribe aquí tu e-mail"
								/>

								<MaterialInput
									id="password"
									formik={formik}
									type="password"
									label="Contraseña*"
									placeholder="Escribe aquí tu contraseña"
								/>

								<a href="#" className="forgot-password">
									¿Olvidaste tu contraseña?
								</a>

								{error ? (
									<div className="error-message-global">
										Credenciales incorrectas.
									</div>
								) : null}

								<Button
									loading={loading}
									type="submit"
									onClick={formik.handleSubmit}
								>
									Login
								</Button>
							</Form>
						</>
					)}
				</Formik>
			</div>
		</AuthLayout>
	);
};

export default LoginComponent;
