import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getCoursesService = async (id) => {
	try {
		let response = await customFetch(`${endpoints.courses}`, {
			method: "GET",
			token: getUserToken()
		});

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const getCourseService = async (id) => {
	try {
		let response = await customFetch(`${endpoints.courses}/${id}`, {
			method: "GET",
			token: getUserToken()
		});

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const getCoursesPaginationService = async (args) => {
	try {
		const { limit = 10, skip = 0 } = args;
		let response = await customFetch(
			`${endpoints.courses}?filter[limit]=${limit}&filter[skip]=${skip}`,
			{
				method: "GET",
				token: getUserToken()
			}
		);

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const getCoursesTotalService = async () => {
	try {
		let response = await customFetch(`${endpoints.courses}/count`, {
			method: "GET",
			token: getUserToken()
		});

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const createCourseService = async (data) => {
	try {
		let response = await customFetch(`${endpoints.courses}`, {
			method: "POST",
			bodyReq: data,
			token: getUserToken()
		});

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const editCourseService = async (data, courseId) => {
	try {
		let response = await customFetch(`${endpoints.courses}/${courseId}`, {
			method: "PATCH",
			bodyReq: data,
			token: getUserToken()
		});

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const uploadCoursePictureService = async ({ url, body, mimeType }) => {
	try {
		let response = await fetch(url, {
			method: "PUT",
			body: body,
			headers: {
				"Content-Type": mimeType,
				"x-amz-acl": "public-read",
				"Content-Encoding": "base64"
			}
		});

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const getUploadCoursePictureService = async ({
	key,
	mimeType,
	source = "s3",
	view = "public-read"
}) => {
	try {
		let response = await customFetch(`${endpoints.courses}/image`, {
			method: "POST",
			bodyReq: { key, mimeType, source, view },
			token: getUserToken()
		});

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const confirmAssistanceService = async (courseId, profileId) => {
	try {
		let response = await customFetch(
			`${endpoints.courses}/${courseId}/confirm/${profileId}`,
			{
				method: "POST",
				token: getUserToken()
			}
		);

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const deleteCourseService = async (id) => {
	try {
		let response = await customFetch(`${endpoints.courses}/${id}`, {
			method: "DELETE",
			token: getUserToken()
		});

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};
