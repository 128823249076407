import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser } from "redux/actions/userActions";
import MembershipCertificatesComponent from "../components/MembershipCertificatesComponent";
import {
	getCertificateByIdService,
	getMembershipCertificates
} from "services/certificates";
import { getUsersService } from "services/user";

const MembershipCertificates = (props) => {
	const [isManageCertificateModalOpen, setIsManageCertificateModalOpen] =
		useState(false);
	const [submitCertificateLoading, setSubmitCertificateLoading] =
		useState(false);
	const [certificates, setCertificates] = useState([]);
	const [certificatesFetching, setCertificatesFetching] = useState(false);
	const [user, setUser] = useState({});
	const [certificationRequest, setCertificationRequest] = useState({});

	useEffect(() => {
		getCertificates();
	}, []);

	const getUser = async (certificate) => {
		return getUsersService(certificate.profileId).then((user) => {
			certificate.name = user.name;
			certificate.surname = user.surname;
			return certificate;
		});
	};

	const getData = async (data) => {
		return await Promise.all(data.map((certificate) => getUser(certificate)));
	};

	const getCertificates = async () => {
		setCertificatesFetching(true);
		try {
			const data = await getMembershipCertificates();
			let newData = await getData(data);
			setCertificates(newData);
		} catch (err) {
			console.log(err);
		} finally {
			setCertificatesFetching(false);
		}
	};

	const getCertificateById = async (id) => {
		try {
			const certificate = await getCertificateByIdService(id);
			return certificate.url;
		} catch (err) {
			console.log(err);
		}
	};

	const submitCertificate = async (formValues) => {
		setSubmitCertificateLoading(true);
		try {
			await uploadFile(formValues.picture.file, parseInt(formValues.year));
			setIsManageCertificateModalOpen(false);
		} catch (err) {
			console.log(err);
		} finally {
			setSubmitCertificateLoading(false);
		}
	};
	const getUrlFile = async (file) => {
		return file.name;
	};

	const uploadFile = async (file, reason) => {
		return "#";
	};

	/*const uploadFile = async (file, year) => {

        const urlToUpload = await getUploadMembershipCertificatesService({ profileId: id, year, key: file.name, mimeType: file.type });

        const fileBuffer = await getFileBuffer(file);

        const uploaded = await uploadImageService({ url:urlToUpload.url, body:fileBuffer, mimeType:urlToUpload.mimeType });

        const response = await uploadMembershipCertificatesService({ profileId: id, year, file:urlToUpload.fileData });

        let finalUrl = response?.file?.url?.split('?')[0];

        return finalUrl;
    }*/

	return (
		<MembershipCertificatesComponent
			{...props}
			isManageCertificateModalOpen={isManageCertificateModalOpen}
			setIsManageCertificateModalOpen={setIsManageCertificateModalOpen}
			submitCertificateLoading={submitCertificateLoading}
			submitCertificate={submitCertificate}
			getUrlFile={getUrlFile}
			user={user}
			certificationRequest={setCertificationRequest}
			certificates={certificates}
			getCertificateById={getCertificateById}
			certificatesFetching={certificatesFetching}
		/>
	);
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ updateUser }, dispatch);

export default withRouter(
	connect(null, mapDispatchToProps)(MembershipCertificates)
);
