import { store } from "redux/store";

export function getUserToken() {
	// return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmNWI4MTQzZTI0MDhmNGRjMTEzNDAwNiIsInNlc3Npb25JZCI6IjNiYjA0NzRlLTE2OGYtNDg5NS04YjNkLWJkNDhlZjBlN2Q0NCIsImJsb2NrY2hhaW5JZCI6IiIsInByb2ZpbGVJZCI6IjVmNWI4MTQzZTI0MDhmMDEwYzEzNDAwNyIsInJlc291cmNlcyI6WyJuYXZpZ2F0ZUFwcCJdLCJpYXQiOjE2MDAyNDc1NTYsImV4cCI6MTYwMDUwNjc1Nn0.N-R1ioDcWICzFqJwVTf2Ts6qYBnubERkL1DBYUFcqQI';

	const state = store.getState();
	const token = state?.userReducer?.user?.token;
	return token;
}

export function getUserId() {
	const state = store.getState();
	const id = state?.userReducer?.user?.id;
	return id;
}

export const setLastReadLocalStorage = async (lastReadChats) => {
	await localStorage.setItem("lastReadChats", JSON.stringify(lastReadChats));
	return lastReadChats;
};

export const getLastReadLocalStorage = async () => {
	const lastReadChats = (await localStorage.getItem("lastReadChats")) || "{}";
	return JSON.parse(lastReadChats);
};

export function validURL(str) {
	var pattern = new RegExp(
		"^(https?:\\/\\/)?" + // protocol
			"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
			"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
			"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
			"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
			"(\\#[-a-z\\d_]*)?$",
		"i"
	); // fragment locator
	return !!pattern.test(str);
}
