import React from "react";
import Skeleton from "react-loading-skeleton";
import "./SkeletonChatCard.scss";

const SkeletonChatCard = (props) => {
	return (
		<div className="skeleton-chat-card">
			<div className="photo">
				<div className="chat-photo">
					<Skeleton width={70} height={70} />
				</div>
			</div>
			<div className="main-content">
				<div className="title">
					<div className="course-title">
						<Skeleton width={130} height={12} />
					</div>
				</div>

				<div className="description">
					<Skeleton width={150} height={12} />
					<Skeleton width={100} height={12} />
				</div>
			</div>
			<div className="action">
				<div className="dots-icon">
					<Skeleton width={30} height={12} />
				</div>
			</div>
		</div>
	);
};

export default SkeletonChatCard;
