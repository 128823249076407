import React, { useState } from "react";
import "./AuthLayout.scss";

const AuthLayout = (props) => {
	const { children } = props;

	return (
		<div className="auth-layout">
			<div className="background-image">
				<img src={require("assets/images/auth-bg.jpg")} alt="" />
			</div>

			<img
				className="app-logo"
				src={require("assets/images/logo-horizontal.png")}
				alt=""
			/>

			<div className="auth-layout__card">{children}</div>
		</div>
	);
};

export default AuthLayout;
