import React, { useEffect, useState } from "react";
import CourseAttendantsComponent from "../components/CourseAttendantsComponent";
import { getCourseService } from "services/courses";
import { getUsersFiltersService } from "services/user";
import { useParams } from "react-router";

const CourseAttendants = (props) => {
	const [usersMap, setUsersMap] = useState([]);
	const [assistants, setAssistants] = useState([]);
	const [pendingAssistants, setPendingAssistants] = useState([]);

	const [course, setCourse] = useState({});
	const [courseFetching, setCourseFetching] = useState(false);

	const usersFieldsShown = [
		"profileId",
		"name",
		"surname",
		"email",
		"nif",
		"phone"
	];

	let { id } = useParams();

	useEffect(() => {
		getCourse();
	}, []);

	useEffect(() => {
		getAssistants();
	}, [course]);

	const getCourse = async () => {
		setCourseFetching(true);

		try {
			const usersData = await getUsersFiltersService({
				fields: Object.fromEntries(usersFieldsShown.map((key) => [key, true]))
			});
			console.log("ASD", usersData);
			setUsersMap(usersData);

			const data = await getCourseService(id);
			console.log("ASD CURSO", data);
			setCourse(data);
		} catch (err) {
			console.log(err);
		}

		setCourseFetching(false);
	};

	const getAssistants = async () => {
		if (course.confirmed) {
			let currentAssistants = [];

			course.confirmed.forEach((profileId, i) => {
				currentAssistants.push(
					usersMap.find((user) => user.profileId === profileId)
				);
			});

			setAssistants(currentAssistants);
		}
		if (course.pending) {
			const pending = [];
			course.pending.forEach((profileId, i) => {
				if (course.confirmed.indexOf(profileId) == -1)
					pending.push(usersMap.find((user) => user.profileId === profileId));
			});
			setPendingAssistants(pending);
		}
	};

	return (
		<CourseAttendantsComponent
			assistants={assistants}
			pendingAssistants={pendingAssistants}
			course={course}
			courseFetching={courseFetching}
		/>
	);
};

export default CourseAttendants;
