import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getUploadDonationCertificateService = async ({
	profileId,
	year,
	key,
	mimeType,
	source = "s3"
}) => {
	try {
		let response = await customFetch(`${endpoints.donationCertificates}/file`, {
			method: "POST",
			bodyReq: { profileId, year, key, mimeType, source },
			token: getUserToken()
		});

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const getUploadMembershipCertificatesService = async ({
	profileId,
	year,
	key,
	mimeType,
	source = "s3"
}) => {
	/*try {
        let response = await customFetch(`${endpoints.donationCertificates}/file`, {
            method: "POST",
            bodyReq: { profileId, year, key, mimeType, source},
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }*/

	return {};
};

export const uploadDonationCertificateService = async ({
	profileId,
	year,
	file: { url, key, source, view, mimeType }
}) => {
	try {
		let response = await customFetch(`${endpoints.donationCertificates}`, {
			method: "POST",
			bodyReq: { profileId, year, file: { url, key, source, view, mimeType } },
			token: getUserToken()
		});

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const uploadMembershipCertificatesService = async ({
	profileId,
	year,
	file: { url, key, source, view, mimeType }
}) => {
	/*try {
        let response = await customFetch(`${endpoints.donationCertificates}`, {
            method: "POST",
            bodyReq: { profileId, year, file : {url, key, source, view, mimeType }},
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }*/

	return {};
};

export const uploadImageService = async ({ url, body, mimeType }) => {
	let response = await fetch(url, {
		method: "put",
		body: body,
		headers: {
			"Content-Type": mimeType,
			"x-amz-acl": "private",
			"Content-Encoding": "base64"
		}
	});

	return JSON.stringify(response.ok);
};

export const getCertificatesService = async (id) => {
	try {
		let response = await customFetch(
			`${endpoints.donationCertificates}?filter[where][profileId]=${id}`,
			{
				method: "GET",
				token: getUserToken()
			}
		);

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const getMembershipCertificates = async () => {
	try {
		let response = await customFetch(`${endpoints.membershipCertificates}`, {
			method: "GET",
			token: getUserToken()
		});

		console.log(response);
		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const getCertificateByIdService = async (id) => {
	console.log(`${endpoints.donationCertificates}/${id}`);
	try {
		let response = await customFetch(
			`${endpoints.adminDonationCertificates}/${id}`,
			{
				method: "GET",
				token: getUserToken()
			}
		);

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};
