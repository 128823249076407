import { UPDATE_GROUP } from "../types/groupTypes";

const savedGroup = localStorage.getItem("admin_group_token");

const initialState = {
	group: savedGroup ? JSON.parse(savedGroup) : {}
};

export const groupReducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_GROUP:
			localStorage.setItem("admin_group_token", JSON.stringify(action.group));

			return {
				...state,
				group: action.group
			};

		default:
			return state;
	}
};
