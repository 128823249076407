import React, { useState } from "react";
import { FaEllipsisV, FaRegCalendar, FaRegUser } from "react-icons/fa";
import { AiOutlineEdit } from "react-icons/ai";
import { GoArchive } from "react-icons/go";
import OutsideClickHandler from "react-outside-click-handler";
import ReactHtmlParser from "react-html-parser";
import "./GroupCard.scss";

const GroupCard = (props) => {
	const {
		picture,
		title,
		date,
		description = "",
		messagesCount = 0,
		places,
		sessions,
		deleteClickCallback,
		editClickCallback,
		active = true
	} = props;
	const [optionsOpen, setOptionsOpen] = useState(false);

	//console.log(picture);

	let realPicture = picture;
	if (picture == "https://placehold.it/100x100")
		realPicture = "https://via.placeholder.com/100";

	return (
		<div
			className="group-card"
			style={{ backgroundColor: active ? "#fff" : "#eee" }}
		>
			{picture && (
				<div className="picture">
					<img src={realPicture} alt="" />
				</div>
			)}

			<div className="main-content">
				<div className="title">
					<div className="course-title">
						{title}
						{!active && <i> (Archivado)</i>}
					</div>
					{date && (
						<div className="date">
							<FaRegCalendar />
							<span>{date}</span>
						</div>
					)}
				</div>

				<div className="description">{ReactHtmlParser(description)}</div>
				<div className="description">
					{`${messagesCount} ` + (messagesCount === 1 ? "mensaje" : "mensajes")}
				</div>
			</div>

			<div className="secondary-content">
				{places && (
					<div className="places">
						<FaRegUser />
						<span>{places} plazas</span>
					</div>
				)}
				{sessions && <div className="sessions">3 sesiones</div>}
			</div>

			{active && (
				<OutsideClickHandler onOutsideClick={(e) => setOptionsOpen(false)}>
					<div className="action">
						<div
							className="dots-icon"
							onClick={() => setOptionsOpen(!optionsOpen)}
						>
							<FaEllipsisV />
						</div>

						{optionsOpen && (
							<div className="options">
								{editClickCallback && (
									<div className="option" onClick={editClickCallback}>
										<AiOutlineEdit size={"20px"} />
										<span>Editar</span>
									</div>
								)}

								<div className="option" onClick={deleteClickCallback}>
									<GoArchive size={"19px"} />
									<span>Archivar</span>
								</div>
							</div>
						)}
					</div>
				</OutsideClickHandler>
			)}
		</div>
	);
};

export default GroupCard;
