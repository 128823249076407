import { getAuth, signInWithCustomToken } from "firebase/auth";
import { db } from "../firebase";
import customFetch from "./customFetch";
import { endpoints } from "./endpoints";
import { getUserToken } from "./utils";

import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	query,
	setDoc,
	updateDoc,
	where
} from "firebase/firestore";
import { orderBy } from "lodash";

export const getDebateGroupsService = async () => {
	const q = query(collection(db, "discussionGroup"));
	const querySnapshot = await getDocs(q);
	const groupsPromisesWithMessages = querySnapshot.docs.map(
		async (debateGroup) => {
			const messagesQuery = query(
				collection(db, "discussionGroup", debateGroup.id, "messages")
			);
			const messagesSnapshot = await getDocs(messagesQuery);
			const messagesCount = messagesSnapshot.docs.length;
			const groupAPI = await getDebateGroupAPI(debateGroup.id);
			let memberIds = [];
			let description = "";
			let startDate = new Date();
			let endDate = new Date();
			let active = false;
			if (groupAPI || groupAPI[0]) {
				memberIds = groupAPI[0].memberIds;
				description = groupAPI[0].description;
				startDate = groupAPI[0].startDate;
				endDate = groupAPI[0].endDate;
				active = groupAPI[0].active;
			}

			return {
				...debateGroup.data(),
				messagesCount,
				id: debateGroup.id,
				description,
				memberIds,
				startDate,
				endDate,
				active
			};
		}
	);
	const groups = await Promise.all(groupsPromisesWithMessages);
	return groups;
};

const getDebateGroupAPI = async (id) => {
	try {
		let response = await customFetch(
			`${endpoints.chat_debate}?filter[where][debateGroupId]=${id}`,
			{
				method: "GET",
				token: getUserToken()
			}
		);

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const createDebateGroupService = async (newGroupData) => {
	try {
		const docRef = await addDoc(
			collection(db, "discussionGroup"),
			newGroupData
		);

		const groupData = {
			...newGroupData,
			id: docRef.id,
			messagesCount: 0
		};

		const result = await customFetch(endpoints.webhook, {
			method: "POST",
			token: getUserToken(),
			bodyReq: {
				receiverIds: newGroupData.memberIds,
				chatRoom: "debate",
				chatId: docRef.id,
				title: "Te has unido a un nuevo grupo",
				message: newGroupData.name
			}
		});
		return groupData;
	} catch (err) {
		throw err;
	}
};

export const createDebateGroupAPI = async (
	debateGroupId,
	name,
	description,
	memberIds,
	startDate,
	endDate
) => {
	try {
		const response = await customFetch(endpoints.chat_debate, {
			method: "POST",
			token: getUserToken(),
			bodyReq: {
				debateGroupId,
				name,
				description,
				memberIds,
				startDate,
				endDate
			}
		});
		return response;
	} catch (e) {
		console.log("error createDebateGroupAPI");
		throw e;
	}
};

export const updateDebateGroupService = async (
	groupData,
	groupId,
	newParticipants = []
) => {
	try {
		const groupDocRef = doc(db, "discussionGroup", groupId);

		const { messagesCount, ...updateData } = groupData;

		await updateDoc(groupDocRef, updateData);

		const messagesQuery = query(
			collection(db, "discussionGroup", groupId, "messages")
		);
		const messagesSnapshot = await getDocs(messagesQuery);
		const newMessagesCount = messagesSnapshot.docs.length;

		const updatedGroupData = {
			...updateData,
			messagesCount: newMessagesCount
		};

		await customFetch(endpoints.webhook, {
			method: "POST",
			token: getUserToken(),
			bodyReq: {
				receiverIds: newParticipants,
				chatRoom: "debate",
				chatId: groupId,
				title: "Te has unido a un nuevo grupo",
				message: groupData.name
			}
		});

		return updatedGroupData;
	} catch (err) {
		throw err;
	}
};

export const updateDebateGroupDataAPI = async (
	debateGroupId,
	name,
	description,
	memberIds,
	startDate,
	endDate
) => {
	try {
		let response = await customFetch(
			`${endpoints.chat_debate}/${debateGroupId}`,
			{
				method: "PATCH",
				token: getUserToken(),
				bodyReq: {
					debateGroupId,
					name,
					description,
					memberIds,
					startDate,
					endDate
				}
			}
		);

		return response;
	} catch (e) {
		console.log("error updateDebateGroupDataAPI");
		throw e;
	}
};

export const deleteDebateGroupService = async (groupId) => {
	try {
		await deleteDoc(doc(db, "discussionGroup", groupId));
		await deleteDebateGroupAPI(groupId);
		return true;
	} catch (err) {
		throw err;
	}
};

const deleteDebateGroupAPI = async (id) => {
	try {
		let response = await customFetch(`${endpoints.chat_debate}/${id}`, {
			method: "DELETE",
			token: getUserToken()
		});

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const archiveDebateGroupService = async (groupId) => {
	try {
		let response = await customFetch(
			`${endpoints.chat_debate}/${groupId}/archive`,
			{
				method: "PATCH",
				token: getUserToken()
			}
		);

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const getInterestGroupsService = async () => {
	const groupsQuery = query(collection(db, "groups"));
	const querySnapshot = await getDocs(groupsQuery);
	const groupsPromisesWithMessages = querySnapshot.docs.map(
		async (interestGroup) => {
			const messagesQuery = query(
				collection(db, "groups", interestGroup.id, "messages")
			);
			const messagesSnapshot = await getDocs(messagesQuery);
			const messagesCount = messagesSnapshot.docs.length;
			const groupAPI = await getInterestGroupAPI(interestGroup.id);
			let active = false;
			let description = "";
			if (groupAPI || groupAPI[0]) {
				active = groupAPI[0].active;
				description = groupAPI[0].description;
			}
			return {
				...interestGroup.data(),
				messagesCount,
				id: interestGroup.id,
				active,
				description
			};
		}
	);
	const groups = await Promise.all(groupsPromisesWithMessages);
	return groups;
};

const getInterestGroupAPI = async (id) => {
	try {
		let response = await customFetch(
			`${endpoints.chat_interest}?filter[where][interestGroupId]=${id}`,
			{
				method: "GET",
				token: getUserToken()
			}
		);

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const createInterestGroupService = async (newGroupData) => {
	try {
		const docRef = await addDoc(collection(db, "groups"), newGroupData);
		const groupData = {
			...newGroupData,
			id: docRef.id,
			messagesCount: 0
		};
		return groupData;
	} catch (err) {
		throw err;
	}
};

export const createInterestGroupAPI = async (
	interestGroupId,
	name,
	description,
	active = true
) => {
	try {
		const response = await customFetch(endpoints.chat_interest, {
			method: "POST",
			token: getUserToken(),
			bodyReq: { interestGroupId, name, description, active }
		});

		return response;
	} catch (e) {
		console.log("error createInterestGroupAPI");
		throw e;
	}
};

export const updateInterestGroupService = async (groupData, groupId) => {
	try {
		const groupDocRef = doc(db, "groups", groupId);
		const { messagesCount, ...updateData } = groupData;

		await updateDoc(groupDocRef, updateData);

		const messagesQuery = query(collection(db, "groups", groupId, "messages"));
		const messagesSnapshot = await getDocs(messagesQuery);
		const newMessagesCount = messagesSnapshot.docs.length;

		const updatedGroupData = {
			...updateData,
			messagesCount: newMessagesCount
		};

		return updatedGroupData;
	} catch (err) {
		throw err;
	}
};

export const updateInterestGroupDataAPI = async (
	interestGroupId,
	name,
	description,
	active = true
) => {
	try {
		let response = await customFetch(
			`${endpoints.chat_interest}/${interestGroupId}`,
			{
				method: "PATCH",
				token: getUserToken(),
				bodyReq: { interestGroupId, name, description, active }
			}
		);

		return response;
	} catch (e) {
		console.log("error updateInterestGroupDataAPI");
		throw e;
	}
};

export const deleteInterestGroupService = async (groupId) => {
	try {
		const groupDocRef = doc(db, "groups", groupId);

		await deleteDoc(groupDocRef);

		await deleteInterestGroupAPI(groupId);

		return true;
	} catch (err) {
		throw err;
	}
};

const deleteInterestGroupAPI = async (id) => {
	try {
		let response = await customFetch(`${endpoints.chat_interest}/${id}`, {
			method: "DELETE",
			token: getUserToken()
		});

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const archiveInterestGroupService = async (groupId) => {
	try {
		let response = await customFetch(
			`${endpoints.chat_interest}/${groupId}/archive`,
			{
				method: "PATCH",
				token: getUserToken()
			}
		);

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const chatLoginService = async () => {
	try {
		const response = await customFetch(endpoints.get_firebase_admin_token, {
			method: "GET",
			token: getUserToken()
		});

		console.log("FIREBASE TOKEN", response);

		const auth = getAuth();

		const firebaseResponse = await signInWithCustomToken(
			auth,
			response.token
		).catch((error) => {
			console.log("Error al logearse a FIREBASE", error.code, error.message);
			throw error;
		});

		return firebaseResponse;
	} catch (e) {
		console.log("error at logging in firebase");
		throw e;
	}
};

export const getContactsInformationService = async () => {
	try {
		const response = await customFetch(endpoints.get_contacts, {
			method: "GET",
			token: getUserToken()
		});

		if (response.error) throw response;

		return response;
	} catch (err) {
		console.log(err);
		throw err;
	}
};

export const getContactsService = async (callback) => {
	const q = query(collection(db, "contacts"));

	const unsubscribe = onSnapshot(q, (snapshot) => {
		snapshot.docChanges().forEach((change) => {
			if (
				change.type === "added" ||
				change.type === "modified" ||
				change.type === "removed"
			) {
				const newContact = {
					...change.doc.data(),
					id: change.doc.id,
					type: "contact"
				};

				callback(newContact);
			}
		});
	});

	return unsubscribe;
};

export const getPrivateChatsService = async (userId, callback) => {
	const q = query(
		collection(db, "privateChats"),
		where("participants", "array-contains", userId)
		// orderBy("updatedAt", "desc")
	);

	const unsubscribe = onSnapshot(q, (snapshot) => {
		console.log({ snapshot });
		snapshot.docChanges().forEach((change) => {
			const newChat = {
				...change.doc.data(),
				id: change.doc.id,
				type: "private"
			};
			console.log({ newChat });

			callback(newChat);
		});
	});

	return unsubscribe;
};

export const getUnreadMessagesCount = async (
	chatId,
	lastReadTimestamp,
	chatType
) => {
	const collectionPath = chatType === "public" ? "groups" : "privateChats";

	const messagesCollectionRef = collection(
		db,
		collectionPath,
		chatId,
		"messages"
	);

	const q = query(
		messagesCollectionRef,
		where("timestamp", ">", lastReadTimestamp)
	);
	const querySnapshot = await getDocs(q);

	return querySnapshot.docs.length;
};

export const listenChatIdService = (id, callback, chatType) => {
	const collectionPath = chatType === "public" ? "groups" : "privateChats";

	const messagesCollectionRef = collection(db, collectionPath, id, "messages");

	const q = query(messagesCollectionRef, orderBy("timestamp", "asc"));

	const unsubscribe = onSnapshot(q, (snapshot) => {
		snapshot.docChanges().forEach((change) => {
			const newMessage = { ...change.doc.data(), id: change.doc.id };
			callback(newMessage);
		});
	});

	return unsubscribe;
};

export const sendMessageService = async (chatId, message, chatType) => {
	const collectionPath =
		chatType === "public"
			? "groups"
			: chatType === "discussionGroup"
				? "discussionGroup"
				: "privateChats";

	const messagesCollectionRef = collection(
		db,
		collectionPath,
		chatId,
		"messages"
	);
	const documentRef = await addDoc(messagesCollectionRef, message);
	message.id = documentRef.id;

	const chatDocRef = doc(db, collectionPath, chatId);
	await updateDoc(chatDocRef, {
		lastMessage: message,
		updatedAt: new Date().getTime()
	});

	const chatSnapshot = await getDoc(chatDocRef);
	if (chatSnapshot.exists()) {
		const chatData = chatSnapshot.data();
		const participants = chatData.participants.filter(
			(p) => p !== message.userId
		);

		await sendPushNotification(chatId, participants, chatType);
	}
};

const sendPushNotification = async (chatId, receiverIds, chatType) => {
	let chatRoom = "";
	switch (chatType) {
		case "public":
			chatRoom = "interest";
			break;
		case "private":
			chatRoom = "private";
			break;
		case "discussionGroup":
			chatRoom = "debate";
			break;
		default:
			break;
	}

	try {
		await customFetch(endpoints.webhook, {
			method: "POST",
			token: getUserToken(),
			bodyReq: {
				receiverIds,
				chatRoom,
				chatId,
				title: "Nuevo Mensaje de Chat",
				message: "Tienes un nuevo mensaje"
			}
		});
	} catch (error) {
		console.log("error sendPushNotification", error);
	}
};

export const createPrivateChat = async (contact, userId) => {
	if (!userId || !contact.id) return;

	const chatKey1 = `${userId}|AND|${contact.id}`;
	const chatKey2 = `${contact.id}|AND|${userId}`;

	let chatExists = await getPrivateChat(chatKey1);
	if (!chatExists) {
		chatExists = await getPrivateChat(chatKey2);
	}

	if (chatExists) return chatExists;
	else {
		const newChatData = {
			participants: [userId, contact.id],
			lastMessage: {},
			timestamp: new Date().getTime(),
			updatedAt: new Date().getTime()
		};

		const chatDocRef = doc(db, "privateChats", chatKey1);

		await setDoc(chatDocRef, newChatData);

		return { ...newChatData, id: chatKey1 };
	}
};

export const getPrivateChat = async (chatId) => {
	const chatDocRef = doc(db, "privateChats", chatId);
	const docSnap = await getDoc(chatDocRef);
	if (docSnap.exists()) {
		return { id: chatId, ...docSnap.data() };
	} else {
		return null;
	}
};
