import React from "react";
import "./CanceledComponent.scss";
import Lottie from "react-lottie";
import animationData from "assets/lotties/atencion.json";

const CanceledComponent = (props) => {
	return (
		<div>
			<div className="loading">
				<Lottie options={defaultOptions} height={150} width={150} />
			</div>
			<span className="text">
				{" "}
				Operación cancelada por el usuario. <br></br>Haga click en cancelar para
				volver a la aplicación{" "}
			</span>
		</div>
	);
};

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice"
	}
};

export default CanceledComponent;
