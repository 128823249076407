import React, { useEffect, useState } from "react";
import DashboardComponent from "../components/DashboardComponent";
import { getPaymentsPendingService } from "services/payments";
import { getUsersService } from "services/user";
import _ from "lodash";
import moment from "moment";

const Dashboard = (props) => {
	const [totalUsers, setTotalUsers] = useState(0);
	const [payments, setPayments] = useState([]);

	useEffect(() => {
		getPayments();
	}, []);

	const getPayments = async () => {
		try {
			const usersData = await getUsersService();
			setTotalUsers(usersData.length);
			const paymentsDB = await getPaymentsPendingService();

			const payments = paymentsDB.map(function (p) {
				p.profile = _.find(usersData, (u) => u.profileId === p.profileId);
				p.paymentDate = "N/D";

				switch (p.paymentType) {
					case "donation":
						p.paymentType = "Donación";
						break;
					case "course":
						p.paymentType = "Curso";
						break;
					case "carnet":
						p.paymentType = "Carnet";
						break;
					default:
						break;
				}
				if (p.createdAt != undefined) {
					p.paymentDate = moment(p.createdAt).format("DD/MM/YYYY HH:mm");
				}
				return p;
			});

			setPayments(payments);
		} catch (error) {
			console.log(error);
		}
	};

	return <DashboardComponent payments={payments} totalUsers={totalUsers} />;
};

export default Dashboard;
