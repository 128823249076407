import React, { useEffect, useState, useRef } from "react";
import InterestGroupsComponent from "../components/InterestGroupsComponent";
import {
	getInterestGroupsService,
	createInterestGroupService,
	createInterestGroupAPI,
	archiveInterestGroupService,
	updateInterestGroupService,
	updateInterestGroupDataAPI,
	chatLoginService
} from "services/chat";
import ReactQuill from "react-quill";
import _ from "lodash";
import {
	getUploadCoursePictureService,
	uploadCoursePictureService
} from "services/courses";
import { getFileBuffer } from "utils/imageFormatter";

const InterestGroups = (props) => {
	const [groups, setGroups] = useState([]);
	const [groupsFetching, setGroupsFetching] = useState(false);
	const [submitGroupLoading, setSubmitGroupLoading] = useState(false);
	const [deleteGroupLoading, setDeleteGroupLoading] = useState(false);
	const [isManageGroupModalOpen, setIsManageGroupModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState(null);
	const [usersSelected, setUsersSelected] = useState([]);
	const reactQuillRef = useRef(null);
	const uploadFile = async (file) => {
		const urlToUpload = await getUploadCoursePictureService({
			key: file.name,
			mimeType: file.type
		});

		const fileBuffer = await getFileBuffer(file);

		const response = await uploadCoursePictureService({
			url: urlToUpload.url,
			mimeType: file.type,
			body: fileBuffer
		});

		let finalUrl = response?.url?.split("?")[0];

		return finalUrl;
	};

	useEffect(() => {
		getDebateGroups();
		chatLoginService();
	}, []);

	useEffect(() => {
		setUsersSelected([]);
	}, [selectedGroup]);

	const getDebateGroups = async () => {
		setGroupsFetching(true);

		try {
			const groups = await getInterestGroupsService();
			//const groupsSortedByName = _.orderBy(groups, (debate) => debate.name.toLowerCase(), ['asc']);
			//setGroups(groupsSortedByName);

			groups.sort(function (a, b) {
				if (b.active != a.active) {
					return b.active - a.active;
				} else {
					return b.timestamp - a.timestamp > 0 ? 1 : -1;
				}
			});

			setGroups(groups);
		} catch (err) {
			console.log(err);
		}

		setGroupsFetching(false);
	};

	const submitGroup = async (formValues, groupId) => {
		setSubmitGroupLoading(true);

		try {
			let data;

			if (groupId) {
				const groupIndex = groups.findIndex((group) => group.id === groupId);

				const updatedGroupData = {
					...groups[groupIndex],
					name: formValues.name,
					description: formValues.description,
					photo: formValues?.picture?.url || "https://via.placeholder.com/100"
				};

				data = await updateInterestGroupService(updatedGroupData, groupId);
				updateInterestGroupDataAPI(
					data.id,
					formValues.nam,
					formValues.description
				);
				let newGroups = _.cloneDeep(groups);
				newGroups[groupIndex] = data;
				setGroups(newGroups);
			} else {
				const groupData = {
					name: formValues.name,
					description: formValues.description,
					participants: [],
					photo: formValues?.picture?.url || "https://via.placeholder.com/100",
					timestamp: +new Date(),
					type: "public"
				};

				data = await createInterestGroupService(groupData);

				createInterestGroupAPI(
					data.id,
					formValues.name,
					formValues.description
				);

				setGroups([data, ...groups]);
			}

			setIsManageGroupModalOpen(false);
			setSelectedGroup(null);
		} catch (err) {
			console.log(err);
		}

		setSubmitGroupLoading(false);
	};

	const deleteGroup = async (groupId) => {
		setDeleteGroupLoading(true);

		try {
			//await deleteInterestGroupService(groupId);
			await archiveInterestGroupService(groupId);
			/*const groupsWithoutDeleted = groups.filter(group => group.id !== groupId);
            setGroups(groupsWithoutDeleted);*/
			const groupsWithArchived = groups.map((group) =>
				group.id == groupId ? { ...group, active: false } : group
			);
			setGroups(groupsWithArchived);
			setIsDeleteModalOpen(false);
			setSelectedGroup(null);
		} catch (err) {
			console.log(err);
		}

		setDeleteGroupLoading(false);
	};

	const checkCharacterCount = (event) => {
		const editor = reactQuillRef.current.getEditor();
		const unprivilegedEditor =
			reactQuillRef.current.makeUnprivilegedEditor(editor);

		if (unprivilegedEditor.getLength() > 500 && event.key !== "Backspace")
			event.preventDefault();
	};

	return (
		<InterestGroupsComponent
			groups={groups}
			groupsFetching={groupsFetching}
			submitGroup={submitGroup}
			deleteGroup={deleteGroup}
			submitGroupLoading={submitGroupLoading}
			deleteGroupLoading={deleteGroupLoading}
			setIsManageGroupModalOpen={setIsManageGroupModalOpen}
			isManageGroupModalOpen={isManageGroupModalOpen}
			setIsDeleteModalOpen={setIsDeleteModalOpen}
			isDeleteModalOpen={isDeleteModalOpen}
			selectedGroup={selectedGroup}
			setSelectedGroup={setSelectedGroup}
			usersSelected={usersSelected}
			setUsersSelected={setUsersSelected}
			checkCharacterCount={checkCharacterCount}
			reactQuillRef={reactQuillRef}
			uploadFile={uploadFile}
		/>
	);
};

export default InterestGroups;
