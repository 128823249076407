import React, { useState } from "react";
import "./MembershipCertificatesComponent.scss";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import MaterialInput from "components/elements/MaterialInput/MaterialInput";
import OutsideClickHandler from "react-outside-click-handler";
import Button from "components/elements/Button/Button";
import { AiOutlineEdit, AiOutlineUpload } from "react-icons/ai";
import { FaRegTrashAlt, FaEllipsisV } from "react-icons/fa";
import { Formik, Form } from "formik";
import moment from "moment";
import * as yup from "yup";
import Modal from "react-modal";

const MembershipCertificatesComponent = (props) => {
	const {
		isManageCertificateModalOpen,
		setIsManageCertificateModalOpen,
		submitCertificateLoading,
		submitCertificate,
		getUrlFile,
		certificates
	} = props;
	const [optionsOpen, setOptionsOpen] = useState(null);
	const [uploadingPicture, setUploadingPicture] = useState(false);

	const handlePictureUpload = async (file) => {
		setUploadingPicture(true);
		try {
			const url = await getUrlFile(file);
			return url;
		} catch (e) {
			console.log("e", e);
		} finally {
			setUploadingPicture(false);
		}
	};

	return (
		<DashboardLayout>
			<div className="memberships-wrapper">
				<div className="section-title">
					<h1>Certificados de Membresía</h1>
				</div>

				<table>
					<thead>
						<tr>
							<th>Fecha de petición</th>
							<th>Nombre</th>
							<th>Apellidos</th>
							<th>Motivo</th>
						</tr>
					</thead>
					<tbody>
						{certificates.length ? (
							certificates.map((certificate, i) => (
								<tr key={i}>
									<td>{moment(certificate.createdAt).format("DD/MM/YYYY")}</td>
									<td>{certificate.name}</td>
									<td>{certificate.surname}</td>
									<td>{certificate.subject}</td>
									<td>
										<div className="action">
											<div
												className="dots-icon"
												onClick={() =>
													optionsOpen ? setOptionsOpen(null) : setOptionsOpen(i)
												}
											>
												<FaEllipsisV />
											</div>

											{optionsOpen === i && (
												<div className="options">
													<OutsideClickHandler
														onOutsideClick={(e) => {
															e.stopPropagation();
															e.preventDefault();
															setOptionsOpen(null);
														}}
													>
														<div className="option">
															<AiOutlineEdit size={"20px"} />
															<span>Ver</span>
														</div>

														<div className="option">
															<AiOutlineUpload
																size={"20px"}
																onClick={() => {
																	setIsManageCertificateModalOpen(true);
																}}
															/>
															<span>Añadir</span>
														</div>

														<div className="option red">
															<FaRegTrashAlt />
															<span>Eliminar</span>
														</div>
													</OutsideClickHandler>
												</div>
											)}
										</div>
									</td>
								</tr>
							))
						) : (
							<div>No hay usuarios registrados para mostrar.</div>
						)}
					</tbody>
				</table>
			</div>

			<Modal
				isOpen={isManageCertificateModalOpen}
				onRequestClose={() => setIsManageCertificateModalOpen(false)}
				className="sicomoro-modal"
				overlayClassName="modal-overlay with-background"
				ariaHideApp={false}
			>
				<div className="manage-course-modal__wrapper">
					<div className="modal-header">
						<div>Añadir certificado</div>
					</div>

					<Formik
						enableReinitialize
						initialValues={{
							picture: {}
						}}
						validationSchema={yup.object().shape({
							picture: yup.object().shape({
								url: yup.string().required(),
								name: yup.string(),
								mimeType: yup.string()
							}),
							year: yup.number().required()
						})}
						onSubmit={(values) => submitCertificate(values)}
					>
						{(formik) => (
							<Form>
								<div className="modal-body">
									<div className=" fill-3">
										<p>Selecciona el documento a subir:</p>
									</div>

									<div className=" fill-3">
										<MaterialInput
											formik={formik}
											type="pdf"
											id="picture"
											uploadFile={handlePictureUpload}
											loading={uploadingPicture}
										/>
									</div>
								</div>

								<div className="modal-footer">
									<Button
										className="light"
										onClick={() => setIsManageCertificateModalOpen(false)}
									>
										Cancelar
									</Button>
									<Button
										type="submit"
										onClick={() => formik.handleSubmit()}
										loading={submitCertificateLoading}
									>
										Guardar
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</DashboardLayout>
	);
};

export default MembershipCertificatesComponent;
