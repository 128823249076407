import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getPaymentsService = async () => {
	try {
		let response = await customFetch(`${endpoints.payments}`, {
			method: "GET",
			token: getUserToken()
		});

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const getPaymentsPendingService = async (args) => {
	try {
		let response = await customFetch(
			`${endpoints.payments}?filter[where][status]=pending`,
			{
				method: "GET",
				token: getUserToken()
			}
		);

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const getPaymentsPaginationService = async (args) => {
	try {
		const { limit = 10, skip = 0 } = args;
		let response = await customFetch(
			`${endpoints.payments}?filter[limit]=${limit}&filter[skip]=${skip}`,
			{
				method: "GET",
				token: getUserToken()
			}
		);

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const getPaymentsTotalService = async () => {
	try {
		let response = await customFetch(`${endpoints.payments}/count`, {
			method: "GET",
			token: getUserToken()
		});

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const updatePaymentsService = async (paymentId) => {
	try {
		let response = await customFetch(
			`${endpoints.payments}/confirm/${paymentId}`,
			{
				method: "POST",
				bodyReq: { id: paymentId },
				token: getUserToken()
			}
		);

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const getPaymentsMethod = async () => {
	try {
		let response = await customFetch(`${endpoints.paymentMethod}`, {
			method: "GET",
			token: getUserToken()
		});

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};

export const geyPaymentsMethodUser = async (profileId) => {
	if (!profileId) return;

	let query = profileId ? `/?filter[where][profileId]=${profileId}` : "";

	try {
		let response = await customFetch(`${endpoints.paymentMethod}${query}`, {
			method: "GET",
			token: getUserToken()
		});

		if (response.error) throw response;

		return response;
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const getPaymentsPaginationMethod = async (args) => {
	try {
		const { limit = 10, skip = 0 } = args;
		let response = await customFetch(
			`${endpoints.paymentMethod}?filter[limit]=${limit}&filter[skip]=${skip}`,
			{
				method: "GET",
				token: getUserToken()
			}
		);

		if (response.error) throw response;

		return response;
	} catch (err) {
		throw err;
	}
};
