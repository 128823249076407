import React from "react";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const PublicRoute = ({ component: Component, restricted, user, ...rest }) => {
	let isLogged;
	try {
		let userObject = jwt_decode(user.token);
		isLogged = userObject.exp > moment().unix();
	} catch (e) {
		isLogged = false;
	}

	//const isLogged = !_.isEmpty(user);
	const roleRoute = user.role === "Admin" ? "/admin/custom-page" : "/dashboard";

	// restricted = true meaning restricted route when logged in
	return (
		<Route
			{...rest}
			render={(props) =>
				isLogged && restricted ? (
					<Redirect to={roleRoute} />
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

const mapStateToProps = (store) => ({
	user: store.userReducer.user
});

export default connect(mapStateToProps)(PublicRoute);
