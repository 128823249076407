import React from "react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { Router, Switch } from "react-router-dom";
import history from "./services/history";

// Router Components
import PublicRoute from "components/elements/PublicRoute/PublicRoute";
import PrivateRoute from "components/elements/PrivateRoute/PrivateRoute";

// Auth Routes
import Login from "./components/Login/containers/Login";
import Register from "./components/Register/containers/Register";
import RecoverPassword from "./components/RecoverPassword/containers/RecoverPassword";
import RestorePassword from "./components/RestorePassword/containers/RestorePassword";

//Stripe
import Stripe from "./components/Stripe/containers/Stripe";
import Canceled from "./components/Canceled/containers/Canceled";
import Success from "./components/Success/containers/Success";

// Dashboard (all users) Routes
import Dashboard from "./components/Dashboard/containers/Dashboard";
import Users from "./components/Users/containers/Users";
import Courses from "./components/Courses/containers/Courses";
import DebateGroups from "./components/DebateGroups/containers/DebateGroups";
import InterestGroups from "./components/InterestGroups/containers/InterestGroups";
import Chats from "./components/Chats/containers/Chats";
import Notifications from "./components/Notifications/containers/Notifications";
import Events from "./components/Events/containers/Events";
import Payments from "./components/Payments/containers/Payments";
import Suggestions from "./components/Suggestions/containers/Suggestions";
import DonationCertificate from "./components/DonationCertificate/containers/DonationCertificate";
import MembershipCertificates from "./components/MembershipCertificates/containers/MembershipCertificates";
import CourseAttendants from "./components/CourseAttendants/containers/CourseAttendants";
import Youtube from "./components/Youtube/containers/Youtube";

import "./App.scss";

function App() {
	return (
		<Provider store={store}>
			<Router history={history}>
				<div className="App">
					<Switch>
						<PublicRoute restricted component={Login} path="/" exact />
						<PublicRoute restricted component={Login} path="/login" />
						<PublicRoute restricted component={Register} path="/register" />
						<PublicRoute
							restricted
							component={RecoverPassword}
							path="/recover-password"
						/>
						<PublicRoute
							restricted
							component={RestorePassword}
							path="/restore-password"
						/>
						<PublicRoute component={Stripe} path="/stripe" />
						<PublicRoute component={Canceled} path="/canceled" />
						<PublicRoute component={Success} path="/success" />

						<PrivateRoute component={Dashboard} path="/dashboard" />
						<PrivateRoute component={Users} path="/usuarios" />
						<PrivateRoute component={Courses} path="/cursos" />
						<PrivateRoute component={DebateGroups} path="/grupos-debate" />
						<PrivateRoute component={InterestGroups} path="/grupos-interes" />
						<PrivateRoute component={Chats} path="/chats" />
						<PrivateRoute component={Notifications} path="/notificaciones" />
						<PrivateRoute component={Events} path="/eventos" />
						<PrivateRoute component={Payments} path="/pagos" />
						<PrivateRoute component={Suggestions} path="/sugerencias" />
						<PrivateRoute
							component={MembershipCertificates}
							path="/membership-certificates"
						/>
						<PrivateRoute
							component={DonationCertificate}
							path="/donations-certificate/:id"
						/>
						<PrivateRoute
							component={CourseAttendants}
							path="/course-attendants/:id"
						/>
						<PrivateRoute component={Youtube} path="/youtube-videos" />
					</Switch>
				</div>
			</Router>
		</Provider>
	);
}

export default App;
