import React, { useEffect, useState } from "react";
import CoursesComponent from "../components/CoursesComponent";
import {
	getCoursesPaginationService,
	getCoursesTotalService,
	createCourseService,
	deleteCourseService,
	editCourseService,
	getUploadCoursePictureService,
	uploadCoursePictureService
} from "services/courses";
import { getUsersFiltersService } from "services/user";
import { getFileBuffer } from "utils/imageFormatter";
import moment from "moment";

const Courses = (props) => {
	const [courses, setCourses] = useState([]);
	const [coursesFetching, setCoursesFetching] = useState(false);
	const [selectedCourse, setSelectedCourse] = useState({});
	const [usersMap, setUsersMap] = useState([]);
	const [assistants, setAssistants] = useState([]);
	const [submitCourseLoading, setSubmitCourseLoading] = useState(false);
	const [deleteCourseLoading, setDeleteCourseLoading] = useState(false);
	const [isManageCourseModalOpen, setIsManageModalCourseOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isAssistantsModalOpen, setIsAssistantsModalOpen] = useState(false);
	const [paginationTotal, setPaginationTotal] = useState(0);
	const [paginationCurrent, setPaginationCurrent] = useState(1);
	const [paginationStep, setPaginationStep] = useState(10);
	const [paginationUrl, setPaginationUrl] = useState("/cursos/");

	const usersFieldsShown = [
		"profileId",
		"name",
		"surname",
		"email",
		"nif",
		"phone"
	];

	useEffect(() => {
		getCourses();
	}, []);

	useEffect(() => {
		getAssistants();
	}, [selectedCourse]);

	const getCourses = async () => {
		setCoursesFetching(true);

		try {
			//BEGIN Pagination

			const totalPaymentsData = await getCoursesTotalService(); //CHANGE when copying

			const usersData = await getUsersFiltersService({
				fields: Object.fromEntries(usersFieldsShown.map((key) => [key, true]))
			});

			setUsersMap(usersData);

			let totalData = parseInt(totalPaymentsData.count);

			//No payments, function should exit to save up resources.

			if (totalData == 0) {
				setCoursesFetching(false); //CHANGE when copying
				return;
			}

			setPaginationTotal(totalData);

			//Check if pagenumber is ok, elsewhere it returns value to '1'

			let pageNumber = parseInt(
				props.location.pathname.split(paginationUrl)[1]
			);

			if (pageNumber > 1) {
				if (pageNumber * paginationStep - totalData >= paginationStep)
					pageNumber = 1;
			}

			if (pageNumber <= 0) pageNumber = 1;

			if (isNaN(pageNumber)) pageNumber = 1;

			setPaginationCurrent(pageNumber);

			let paginationOptions = {
				limit: paginationStep,
				skip: (pageNumber - 1) * paginationStep
			};

			const data = await getCoursesPaginationService(paginationOptions);

			// END Pagination

			//const data = await getCoursesService();
			setCourses(data);
		} catch (err) {
			console.log(err);
		}

		setCoursesFetching(false);
	};

	const submitCourse = async (formValues, courseId) => {
		setSubmitCourseLoading(true);

		const courseData = {
			name: formValues.name,
			startDate: moment(formValues.startDate).format("YYYY-MM-DDTHH:mm:ss[Z]"),
			endDate: moment(formValues.endDate).format("YYYY-MM-DDTHH:mm:ss[Z]"),
			details: formValues.details,
			content: formValues.content,
			pending: [],
			confirmed: [],
			places: parseInt(formValues.places),
			paymentDetails: {
				quantity: parseInt(formValues.price),
				recurrence: "one-time"
			},
			image: {
				key: formValues.picture?.name,
				url: formValues.picture?.url,
				source: "s3",
				mimeType: formValues.picture?.mimeType,
				view: "public-read"
			}
		};
		// return;
		if (formValues.conditionalPaymentDetails.length > 0) {
			courseData.conditionalPaymentDetails =
				formValues.conditionalPaymentDetails;
		}
		try {
			if (courseId) {
				//edit an existing course
				await editCourseService(courseData, courseId);
				await getCourses();
			} else {
				const data = await createCourseService(courseData);
				setCourses([...courses, data]);
			}
			setIsManageModalCourseOpen(false);
		} catch (err) {
			console.log(err);
		} finally {
			setSubmitCourseLoading(false);
		}
	};

	const deleteCourse = async (courseId) => {
		setDeleteCourseLoading(true);

		try {
			await deleteCourseService(courseId);
			const coursesWithoutDeleted = courses.filter(
				(course) => course.courseId !== courseId
			);
			setCourses(coursesWithoutDeleted);
			setIsDeleteModalOpen(false);
		} catch (err) {
			console.log(err);
		}

		setDeleteCourseLoading(false);
	};

	const uploadFile = async (file) => {
		const urlToUpload = await getUploadCoursePictureService({
			key: file.name,
			mimeType: file.type
		});

		const fileBuffer = await getFileBuffer(file);

		const response = await uploadCoursePictureService({
			url: urlToUpload.url,
			mimeType: file.type,
			body: fileBuffer
		});

		let finalUrl = response?.url?.split("?")[0];

		return finalUrl;
	};

	const getAssistants = async () => {
		if (courses.length > 0) {
			if (selectedCourse.confirmed) {
				let currentAssistants = [];

				selectedCourse.confirmed.forEach((profileId, i) => {
					currentAssistants.push(
						usersMap.find((user) => user.profileId === profileId)
					);
				});

				setAssistants(currentAssistants);
			}
		}
	};

	return (
		<CoursesComponent
			setIsManageModalCourseOpen={setIsManageModalCourseOpen}
			isManageCourseModalOpen={isManageCourseModalOpen}
			isDeleteModalOpen={isDeleteModalOpen}
			setIsDeleteModalOpen={setIsDeleteModalOpen}
			courses={courses}
			selectedCourse={selectedCourse}
			setSelectedCourse={setSelectedCourse}
			submitCourse={submitCourse}
			deleteCourse={deleteCourse}
			submitCourseLoading={submitCourseLoading}
			isAssistantsModalOpen={isAssistantsModalOpen}
			setIsAssistantsModalOpen={setIsAssistantsModalOpen}
			usersMap={usersMap}
			assistants={assistants}
			deleteCourseLoading={deleteCourseLoading}
			coursesFetching={coursesFetching}
			uploadFile={uploadFile}
			paginationTotal={paginationTotal}
			paginationCurrent={paginationCurrent}
			paginationStep={paginationStep}
			paginationUrl={paginationUrl}
		/>
	);
};

export default Courses;
