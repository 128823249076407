import React, { useEffect, useState } from "react";
import PaymentsComponent from "../components/PaymentsComponent";
import {
	getPaymentsPaginationService,
	getPaymentsPaginationMethod,
	updatePaymentsService,
	getPaymentsMethod,
	getPaymentsTotalService
} from "services/payments";
import { getUsersService } from "services/user";
import moment from "moment";

const Payments = (props) => {
	const [payments, setPayments] = useState([]);
	const [paymentsFetching, setPaymentsFetching] = useState(false);
	const [isStripePaymentModalOpen, setIsStripePaymentModalOpen] =
		useState(false);
	const [updatingPayment, setUpdatingPayment] = useState(false);
	const [paginationTotal, setPaginationTotal] = useState(0);
	const [paginationCurrent, setPaginationCurrent] = useState(1);
	const [paginationStep, setPaginationStep] = useState(10);
	const [paginationUrl, setPaginationUrl] = useState("/pagos/");

	useEffect(() => {
		getPayments();
	}, []);

	const getPayments = async () => {
		setPaymentsFetching(true);

		try {
			//BEGIN Pagination

			const totalPaymentsData = await getPaymentsTotalService(); //CHANGE when copying

			let totalData = parseInt(totalPaymentsData.count);

			//No payments, function should exit to save up resources.

			if (totalData == 0) {
				setPaymentsFetching(false); //CHANGE when copying
				return;
			}

			setPaginationTotal(totalData);

			//Check if pagenumber is ok, elsewhere it returns value to '1'

			let pageNumber = parseInt(
				props.location.pathname.split(paginationUrl)[1]
			);

			if (pageNumber > 1) {
				if (pageNumber * paginationStep - totalData >= paginationStep)
					pageNumber = 1;
			}

			if (pageNumber <= 0) pageNumber = 1;

			if (isNaN(pageNumber)) pageNumber = 1;

			setPaginationCurrent(pageNumber);

			let paginationOptions = {
				limit: paginationStep,
				skip: (pageNumber - 1) * paginationStep
			};

			//const paymentsRawData = await getPaymentsService();
			const paymentsRawData =
				await getPaymentsPaginationService(paginationOptions);

			//const paymentMethods = await getPaymentsMethod();
			const paymentMethods =
				await getPaymentsPaginationMethod(paginationOptions);

			// END Pagination

			const usersData = await getUsersService();

			let profilesMap = new Map();
			let paymentMethodsMap = new Map();

			usersData.forEach(function (user) {
				profilesMap.set(user.profileId, user);
			});
			paymentMethods.forEach(function (pm) {
				paymentMethodsMap.set(pm.paymentMethodId, pm);
			});

			let paymentsFormattedData = {};

			paymentsRawData.forEach((payment) => {
				//let ignoreThisPayment = false;
				let profile = profilesMap.get(payment.profileId);
				//if (!profile) ignoreThisPayment = true; //REMOVE THIS LINE if you want to see payments with empty data
				profile = profile || { name: "-", surname: "-", email: "-" };
				// let type = payment.details.stripePriceId ? { platform: "Stripe", display: "Tarjeta", } : { platform: "Tansferencia", display: "Domiciliación bancaria" };
				let type = paymentMethodsMap.get(payment.paymentMethodId) || {};
				//let paymentType = payment.paymentType === 'course' ? 'Curso' : 'Club';
				let paymentType = "N/D";

				switch (payment.paymentType) {
					case "course":
						paymentType = "Curso";
						break;
					case "carnet":
						paymentType = "Club";
						break;
					case "donation":
						paymentType = "Donación";
						break;
				}

				let price = payment.details.stripePriceId;

				let status = "Fallo";
				let statusColor = "#A23436";
				switch (payment.status) {
					case "pending":
						status = "Pendiente";
						statusColor = "#FF9D29";
						break;
					case "cancelled":
						status = "Cancelado";
						break;
					case "confirmed":
						status = "Cobrado";
						statusColor = "#A1C859";
						break;
					default:
						status = "Fallo";
				}

				let paymentMethodName = "Tarjeta";
				switch (type.paymentType) {
					case "stripe":
						paymentMethodName = "Tarjeta";
						break;
					case "bank-account":
						paymentMethodName = "Domiciliación bancaria";
						break;
					default:
						paymentMethodName = "Tarjeta";
				}

				let paymentDate = "N/D";
				if (payment.createdAt != undefined) {
					paymentDate = moment(payment.createdAt).format("DD/MM/YYYY HH:mm");
				}

				//if (!ignoreThisPayment) {
				paymentsFormattedData[payment.paymentId] = {
					...payment,
					profile,
					type,
					statusDisplay: status,
					statusColor: statusColor,
					paymentDate: paymentDate,
					price,
					paymentType,
					paymentMethodName
				};
				// }
			});

			setPayments(paymentsFormattedData);
		} catch (err) {
			console.log(err);
		}

		setPaymentsFetching(false);
	};

	const changePaymentStatus = async (paymentId) => {
		try {
			setUpdatingPayment(true);
			await updatePaymentsService(paymentId);
			await getPayments();
			setIsStripePaymentModalOpen(false);
		} catch (e) {
			console.log("payment update error", e);
		} finally {
			setUpdatingPayment(false);
		}
	};

	return (
		<PaymentsComponent
			payments={payments}
			paymentsFetching={paymentsFetching}
			isStripePaymentModalOpen={isStripePaymentModalOpen}
			setIsStripePaymentModalOpen={setIsStripePaymentModalOpen}
			changePaymentStatus={changePaymentStatus}
			updatingPayment={updatingPayment}
			paginationTotal={paginationTotal}
			paginationCurrent={paginationCurrent}
			paginationStep={paginationStep}
			paginationUrl={paginationUrl}
		/>
	);
};

export default Payments;
