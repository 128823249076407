import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser } from "redux/actions/userActions";
import StripeComponent from "../components/StripeComponent";

import { loadStripe } from "@stripe/stripe-js";

const Stripe = (props) => {
	const [sessionId, setSessionId] = useState(null);
	const [messageError, setMessageError] = useState(null);

	useEffect(() => {
		const params = getQueryParams();
		const { sessionId } = params;
		setSessionId(sessionId);
	}, []);

	useEffect(() => {
		if (sessionId) {
			getCheckout();
		}
	}, [sessionId, process.env.REACT_APP_STRIPE_PUBLIC_KEY]);

	const getCheckout = async () => {
		const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
		const stripe = await stripePromise;
		try {
			const result = await stripe.redirectToCheckout({
				sessionId: sessionId
			});
		} catch (error) {
			setMessageError(error.message);
		}
		if (process.env.REACT_APP_STRIPE_PUBLIC_KEY) {
			console.log("SI hay plubic key");
			console.log(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
		} else {
			console.log("no hay plubic key");
			console.log(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
		}
	};

	const getQueryParams = () => {
		const params = window.location.search
			.replace("?", "")
			.split("&")
			.reduce(
				(r, e) => (
					(r[e.split("=")[0]] = decodeURIComponent(e.split("=")[1])), r
				),
				{}
			);
		return params;
	};

	return <StripeComponent {...props} messageError={messageError} />;
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(Stripe));
