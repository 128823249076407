import React from "react";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import "./DashboardComponent.scss";

const DashboardComponent = (props) => {
	const { payments, totalUsers } = props;

	return (
		<DashboardLayout>
			<div className="dashboard-wrapper">
				<div className="section-title">
					<h1>Dashboard</h1>
				</div>

				<div className="blocks">
					<div className="container total-users">
						<div>
							<h1>{totalUsers}</h1>
							<h4>Nº usuarios registrados</h4>
						</div>
					</div>

					<div className="payments">
						<h3>
							Pagos pendientes <span>({payments.length})</span>
						</h3>
						<table>
							<thead>
								<tr>
									<th>Nombre</th>
									<th>Apellidos</th>
									<th>Email</th>
									<th>Tipo de Pago</th>
								</tr>
							</thead>
							<tbody>
								{payments.map((payment, i) => (
									<tr className="table-row" key={i}>
										<td>{payment.profile?.name}</td>
										<td>{payment.profile?.surname}</td>
										<td>{payment.profile?.email}</td>
										<td>{payment.paymentType} </td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>

				<a href="https://console.firebase.google.com/" target="_blank">
					<div
						className="firebase-container"
						style={{ backgroundImage: "url(/firebase-background.png)" }}
					>
						<img
							src={require("assets/images/firebase-logo.png")}
							with="200"
							alt=""
						/>
					</div>
				</a>

				<iframe src={"https://monitor.pitagorinesgroup.com/login"} />
			</div>
		</DashboardLayout>
	);
};

export default DashboardComponent;
