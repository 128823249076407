import React from "react";
import "./Button.scss";
import Lottie from "react-lottie";
import animationData from "assets/lotties/loading-white.json";

const ButtonComponent = (props) => {
	const { children, onClick, disabled, type, loading, className } = props;
	return (
		<button
			className={`button-component ${disabled ? "disable" : ""} ${className || ""}`}
			onClick={() => onClick && onClick()}
			disabled={disabled || loading}
			type={type}
		>
			{loading ? (
				<div className="button-component-loading">
					<Lottie options={defaultOptions} height={40} width={40} />
				</div>
			) : (
				<div className="button-content">{children}</div>
			)}
		</button>
	);
};

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice"
	}
};

export default ButtonComponent;
