import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser } from "redux/actions/userActions";
import SuccessComponent from "../components/SuccessComponent";

const Success = (props) => {
	return <SuccessComponent {...props} />;
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(Success));
