const BASE_URL =
	process.env.REACT_APP_API_URL ||
	"https://api.dev.actiophi.netchain.pitagorinesgroup.com/";

export const endpoints = {
	user: BASE_URL + "user",
	users: BASE_URL + "users",
	courses: BASE_URL + "courses",
	credentials: BASE_URL + "credentials",
	profiles: BASE_URL + "profiles",
	notifications: BASE_URL + "notifications",
	adminNotifications: BASE_URL + "admin/notifications",
	admin: BASE_URL + "admin",
	payments: BASE_URL + "payments",
	paymentMethod: BASE_URL + "payment-methods",
	suggestions: BASE_URL + "suggestions",
	adminDonationCertificates: BASE_URL + "admin/donation-certificates",
	donationCertificates: BASE_URL + "donation-certificates",
	membershipCertificates: BASE_URL + "certificates",
	get_firebase_admin_token: BASE_URL + "chat/admin/login",
	get_firebase_token: BASE_URL + "chat/login",
	chat_interest: BASE_URL + "chat/interest-groups",
	chat_debate: BASE_URL + "chat/debate-groups",
	get_contacts: BASE_URL + "/chat/contacts",
	youtube: BASE_URL + "youtube",
	webhook: BASE_URL + "chat/webhook",
	carnets: BASE_URL + "carnets",
	carnets_admin: BASE_URL + "admin/carnets"
	/*get_firebase_admin_token: BASE_URL + "/chat/admin/login"*/
};
