import React, { useState, useEffect } from "react";
import RegisterComponent from "../components/RegisterComponent";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser } from "redux/actions/userActions";
import { registerService } from "services/user";

const Register = (props) => {
	const { updateUser, history } = props;
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);

	const submit = async (values) => {
		setLoading(true);

		const data = {
			name: values.name,
			email: values.email,
			password: values.password
		};

		try {
			const response = await registerService(data);
			updateUser(response);
			history.push("/dashboard");
			setLoading(false);
		} catch (error) {
			setError(error.message);
			setLoading(false);
		}
	};

	return (
		<RegisterComponent
			{...props}
			submit={submit}
			loading={loading}
			error={error}
			history={history}
		/>
	);
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(Register));
