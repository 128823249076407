import React, { useState } from "react";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import _ from "lodash";
import Modal from "react-modal";
import Button from "components/elements/Button/Button";
import PaginationNavigator from "components/elements/PaginationNavigator/PaginationNavigator";
import "./PaymentsComponent.scss";
import "gridjs/dist/theme/mermaid.min.css";
import { FiSearch } from "react-icons/fi";
import { FaCircle } from "react-icons/fa";
import { IconContext } from "react-icons";

const PaymentsComponent = (props) => {
	const {
		payments,
		isStripePaymentModalOpen,
		setIsStripePaymentModalOpen,
		changePaymentStatus,
		updatingPayment,
		paginationTotal,
		paginationCurrent,
		paginationStep,
		paginationUrl
	} = props;

	const [selectedPayment, setSelectedPayment] = useState({
		details: {
			stripePriceId: "Sin identificador"
		}
	});

	return (
		<DashboardLayout>
			<div className="payments-wrapper">
				<div className="section-title">
					<h1>Pagos</h1>
				</div>

				<div className="pagination-top">
					<PaginationNavigator
						total={paginationTotal}
						current={paginationCurrent}
						step={paginationStep}
						url={paginationUrl}
					/>
				</div>

				<table>
					<thead>
						<tr>
							<th>Fecha del pago</th>
							<th>Importe</th>
							<th>Nombre</th>
							<th>Apellidos</th>
							<th>Email</th>
							<th>Metodo de Pago</th>
							<th>Tipo de Pago</th>
							<th>Estado</th>
						</tr>
					</thead>
					<tbody>
						{_.keys(payments).length ? (
							_.values(payments).map((payment, i) => (
								<tr className="table-row" key={i}>
									<td>{payment.paymentDate}</td>
									<td>{payment.details.quantity + " €"}</td>
									<td>{payment.profile.name}</td>
									<td>{payment.profile.surname}</td>
									<td>{payment.profile.email}</td>
									<td>{payment.paymentMethodName} </td>
									<td>{payment.paymentType} </td>
									<td>
										<div className="status-cell">
											<IconContext.Provider
												value={{ color: payment.statusColor, size: "10px" }}
											>
												<FaCircle />
											</IconContext.Provider>{" "}
											<span className="status-display">
												{payment.statusDisplay}
											</span>
										</div>
									</td>
									<td>
										<div
											onClick={() => {
												setIsStripePaymentModalOpen(true);
												setSelectedPayment(payment);
											}}
											className="search-icon"
										>
											<FiSearch />
										</div>
									</td>
								</tr>
							))
						) : (
							<div>No hay pagos realizados para mostrar.</div>
						)}
					</tbody>
				</table>

				<div className="pagination-bottom">
					<PaginationNavigator
						total={paginationTotal}
						current={paginationCurrent}
						step={paginationStep}
						url={paginationUrl}
					/>
				</div>
			</div>

			<Modal
				isOpen={isStripePaymentModalOpen}
				onRequestClose={() => setIsStripePaymentModalOpen(false)}
				className="sicomoro-modal"
				overlayClassName="modal-overlay with-background"
				ariaHideApp={false}
			>
				<div className="payment-info-modal__wrapper">
					<div className="modal-header">
						<div>Pago con tarjeta</div>
					</div>

					<div className="modal-body">
						<div className="green-title">
							<h3>ID de transacción</h3>
						</div>
						<div className="content">{selectedPayment.paymentId}</div>
						{selectedPayment.status === "pending" &&
							selectedPayment.type.paymentType === "bank-account" && (
								<div className="change-payment-status">
									<div className="green-title">
										<h3></h3>
									</div>
									<div className="input-wrapper fill-row">
										<Button
											className="secondary"
											loading={updatingPayment}
											onClick={() => {
												changePaymentStatus(selectedPayment.paymentId);
											}}
										>
											Cambiar estado a cobrado
										</Button>
									</div>
								</div>
							)}
					</div>

					<div className="modal-footer">
						<Button
							onClick={() => {
								setIsStripePaymentModalOpen(false);
							}}
						>
							{"Confirmar"}
						</Button>
					</div>
				</div>
			</Modal>
		</DashboardLayout>
	);
};

export default PaymentsComponent;
