import * as toBuffer from "blob-to-buffer";

export const getFileBuffer = (file) => {
	return new Promise((resolve, reject) => {
		toBuffer(file, async (err, buffer) => {
			if (err) reject(err);
			resolve(buffer);
		});
	});
};
