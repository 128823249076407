import React, { useState } from "react";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Button from "components/elements/Button/Button";
import PaginationNavigator from "components/elements/PaginationNavigator/PaginationNavigator";
import MaterialInput from "components/elements/MaterialInput/MaterialInput";
import { FaPaperPlane, FaEllipsisV, FaRegTrashAlt } from "react-icons/fa";
import { AiOutlineEdit } from "react-icons/ai";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import Modal from "react-modal";
import moment from "moment";
import "./NotificationsComponent.scss";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const NotificationsComponent = (props) => {
	const {
		notifications,
		setIsManageModalNotificationOpen,
		isManageNotificationModalOpen,
		submitNotificationLoading,
		createNotification,
		updateNotification,
		deleteNotification,
		paginationTotal,
		paginationCurrent,
		paginationStep,
		paginationUrl
	} = props;
	const [selectedNotification, setSelectedNotification] = useState(false);
	const [optionsOpen, setOptionsOpen] = useState(false);
	const [deleteNotificationLoading, setDeleteNotificationLoading] =
		useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const editClickCallback = (notif) => {
		setSelectedNotification(notif);
		setIsManageModalNotificationOpen(true);
	};
	const deleteClickCallback = (notif) => {
		setSelectedNotification(notif);
		setIsDeleteModalOpen(true);
	};
	return (
		<DashboardLayout>
			<div className="notifications-wrapper">
				<div className="section-title">
					<h1>Notificaciones</h1>
					<Button
						className="secondary small"
						onClick={() => setIsManageModalNotificationOpen(true)}
					>
						<FaPaperPlane />
						<span>Enviar notificación</span>
					</Button>
				</div>

				<div className="pagination-top">
					<PaginationNavigator
						total={paginationTotal}
						current={paginationCurrent}
						step={paginationStep}
						url={paginationUrl}
					/>
				</div>

				<table>
					<thead>
						<tr>
							<th>Fecha</th>
							<th>ID</th>
							<th>Título</th>
							<th>Mensaje</th>
							<th>Enlace</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{notifications.length ? (
							notifications.map((not, i) => (
								<tr key={i}>
									<td>{moment(not.createdAt).format("DD/MM/YYYY HH:mm")}</td>
									<td>{not.notificationId}</td>
									<td>{not.title}</td>
									<td>{not.bodyTruncated}</td>
									{not.link ? (
										<td>
											<Button onClick={() => window.open(not.link)}>Ver</Button>
										</td>
									) : (
										<td>
											<i>N/D</i>
										</td>
									)}
									<td>
										<div
											className="dots-icon"
											onClick={() =>
												setOptionsOpen(
													optionsOpen == not.notificationId
														? false
														: not.notificationId
												)
											}
										>
											<FaEllipsisV />
											{optionsOpen && optionsOpen == not.notificationId ? (
												<div className="options">
													{
														<div
															className="option"
															onClick={() => {
																editClickCallback(not);
															}}
														>
															<AiOutlineEdit size={"20px"} />
															<span>Editar</span>
														</div>
													}

													<div
														className="option red"
														onClick={() => {
															deleteClickCallback(not);
														}}
													>
														<FaRegTrashAlt />
														<span>Eliminar</span>
													</div>
												</div>
											) : null}
										</div>
									</td>
								</tr>
							))
						) : (
							<div className="empty">No hay notificaciones para mostrar.</div>
						)}
					</tbody>
				</table>

				<div className="pagination-bottom">
					<PaginationNavigator
						total={paginationTotal}
						current={paginationCurrent}
						step={paginationStep}
						url={paginationUrl}
					/>
				</div>

				<Modal
					isOpen={isManageNotificationModalOpen}
					onRequestClose={() => {
						setSelectedNotification(false);
						setIsManageModalNotificationOpen(false);
					}}
					className="sicomoro-modal"
					overlayClassName="modal-overlay with-background"
					ariaHideApp={false}
				>
					<div className="manage-course-modal__wrapper">
						<div className="modal-header">
							<div>Enviar notificación</div>
						</div>

						<Formik
							initialValues={
								!selectedNotification
									? {
											title: "",
											body: "",
											link: "",
											pending: false
										}
									: {
											title: selectedNotification?.title,
											body: selectedNotification?.body,
											link: selectedNotification?.link,
											pending: selectedNotification?.pending,
											createdAt: selectedNotification?.createdAt
										}
							}
							validationSchema={yup.object().shape({
								title: yup.string().required("El titulo es obligatorio"),
								body: yup.string().required("La descripción es obligatoria")
							})}
							onSubmit={(values) => {
								if (selectedNotification) {
									updateNotification(
										selectedNotification.notificationId,
										values
									);
									setSelectedNotification(false);
								} else {
									createNotification(values);
								}
							}}
						>
							{(formik) => (
								<Form>
									<div className="modal-body">
										<div className="input-wrapper fill-row">
											<MaterialInput
												formik={formik}
												type="text"
												id="title"
												label="Título notificación"
												placeholder="Escribe aquí el título de la notificación"
											/>
										</div>
										<div className="input-wrapper fill-row">
											<MaterialInput
												formik={formik}
												type="text"
												id="body"
												label="Descripción "
												placeholder="Escribe aquí la descripción de la notificación"
											/>
										</div>
										<div className="input-wrapper fill-row">
											<MaterialInput
												formik={formik}
												type="text"
												id="link"
												label="Link"
												placeholder="Escribe aquí el link de la notificación"
											/>
										</div>
										<div className="input-wrapper fill-row">
											<div className="input-wrapper fill-2">
												<Field
													as={FormControlLabel}
													type="checkbox"
													name="pending"
													control={<Checkbox />}
													label="Programar"
												/>
											</div>
										</div>
										{formik.values.pending ? (
											<div className="input-wrapper fill-4">
												<MaterialInput
													formik={formik}
													type="datetime-local"
													id="createdAt"
													label="Fecha programada"
													placeholder="Fecha de la notificación"
												/>
											</div>
										) : null}
									</div>

									<div className="modal-footer">
										<Button
											className="light"
											onClick={() => {
												setIsManageModalNotificationOpen(false);
											}}
										>
											Cancelar
										</Button>
										<Button
											onClick={() => {
												formik.handleSubmit();
											}}
											loading={submitNotificationLoading}
										>
											Enviar
										</Button>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</Modal>
				<Modal
					isOpen={isDeleteModalOpen}
					onRequestClose={() => {
						setSelectedNotification(false);
						setIsDeleteModalOpen(false);
					}}
					className="sicomoro-modal"
					overlayClassName="modal-overlay with-background"
					contentLabel="Example Modal"
				>
					<div className="delete-modal__wrapper">
						<div className="modal-body">
							<FaRegTrashAlt />
							<h3>¿Eliminar Notificación?</h3>
						</div>
						<div className="modal-footer">
							<Button
								className="light"
								onClick={() => {
									setSelectedNotification(false);
									setIsDeleteModalOpen(false);
								}}
							>
								Cancelar
							</Button>
							<Button
								className="danger"
								loading={deleteNotificationLoading}
								onClick={() => {
									deleteNotification(selectedNotification.notificationId);
									setSelectedNotification(false);
									setIsDeleteModalOpen(false);
								}}
							>
								Eliminar
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</DashboardLayout>
	);
};

export default NotificationsComponent;
