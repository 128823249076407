import React, { useEffect, useState } from "react";
import NotificationComponent from "../components/NotificationsComponent";
import {
	getNotificationsPaginationService,
	getNotificationsTotalService,
	createNotificationService,
	updateNotificationService,
	deleteNotificationService
} from "services/notifications";

const Notifications = (props) => {
	const [notifications, setNotification] = useState([]);
	const [notificationsFetching, setNotificationFetching] = useState(false);
	const [isManageNotificationModalOpen, setIsManageModalNotificationOpen] =
		useState(false);
	const [submitNotificationLoading, setSubmitNotificationLoading] =
		useState(false);
	const characterLimit = 50;
	const [paginationTotal, setPaginationTotal] = useState(0);
	const [paginationCurrent, setPaginationCurrent] = useState(1);
	const paginationStep = 10;
	const paginationUrl = "/notificaciones/";
	/*
    const [deleteCourseLoading, setDeleteCourseLoading] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);*/

	useEffect(() => {
		getNotification();
	}, []);

	const getNotification = async () => {
		setNotificationFetching(true);

		try {
			//BEGIN Pagination

			const totalNotificationsData = await getNotificationsTotalService(); //CHANGE when copying

			let totalData = parseInt(totalNotificationsData.count);

			//No payments, function should exit to save up resources.

			if (totalData == 0) {
				setNotificationFetching(false); //CHANGE when copying
				return;
			}

			setPaginationTotal(totalData);

			//Check if pagenumber is ok, elsewhere it returns value to '1'

			let pageNumber = parseInt(
				props.location.pathname.split(paginationUrl)[1]
			);

			if (pageNumber > 1) {
				if (pageNumber * paginationStep - totalData >= paginationStep)
					pageNumber = 1;
			}

			if (pageNumber <= 0) pageNumber = 1;

			if (isNaN(pageNumber)) pageNumber = 1;

			setPaginationCurrent(pageNumber);

			let paginationOptions = {
				limit: paginationStep,
				skip: (pageNumber - 1) * paginationStep
			};

			//const data = await getNotificationsService();
			const data = await getNotificationsPaginationService(paginationOptions);

			// END Pagination

			data.forEach(function (notification) {
				notification.bodyTruncated =
					notification.body.length > characterLimit
						? notification.body.substring(0, characterLimit) + "..."
						: notification.body;
			});

			setNotification(data);
		} catch (err) {
			console.log(err);
		}

		setNotificationFetching(false);
	};

	const createNotification = async (values) => {
		try {
			setSubmitNotificationLoading(true);
			values.screen = "Notifications";
			const data = await createNotificationService(values);
			getNotification();
			setIsManageModalNotificationOpen(false);
		} catch (e) {
			console.log(e);
		} finally {
			setSubmitNotificationLoading(false);
		}
	};
	const updateNotification = async (id, values) => {
		try {
			setSubmitNotificationLoading(true);
			const data = await updateNotificationService(id, values);
			getNotification();
			setIsManageModalNotificationOpen(false);
		} catch (e) {
			console.log(e);
		} finally {
			setSubmitNotificationLoading(false);
		}
	};

	const deleteNotification = async (id) => {
		try {
			const data = await deleteNotificationService(id);
			getNotification();
		} catch (e) {
			console.log(e);
		} finally {
		}
	};

	return (
		<NotificationComponent
			setIsManageModalNotificationOpen={setIsManageModalNotificationOpen}
			isManageNotificationModalOpen={isManageNotificationModalOpen}
			notifications={notifications}
			notificationsFetching={notificationsFetching}
			submitNotificationLoading={submitNotificationLoading}
			createNotification={createNotification}
			deleteNotification={deleteNotification}
			updateNotification={updateNotification}
			paginationTotal={paginationTotal}
			paginationCurrent={paginationCurrent}
			paginationStep={paginationStep}
			paginationUrl={paginationUrl}
		/>
	);
};

export default Notifications;
